export default {
  title: 'Cookie Richtlinie',
  p1: `In dieser Cookie-Richtlinie wird beschrieben, wie diese Website verwaltet und die Verwendung von Cookies und die Verarbeitung der personenbezogenen Daten der Benutzer/Besucher der Seite gehandhabt werden.`,
  p2: `Diese Cookie-Richtlinie soll Benutzer über unsere Praktiken informieren, die bei der Erfassung von Informationen durch Cookies und/oder andere Tracking-Technologien beim Besuch der Website (nachstehend als "Website" bezeichnet) angewendet werden, und versteht sich als integrierender Bestandteil der Datenschutzrichtlinie der Website.`,
  p3: `Der Verantwortliche für die über die Website erhobenen personenbezogenen Daten ist die Gesellschaft TechVisory srl mit Sitz in Trient (Via Kufstein 5, 38121 Trento, Italien) (nachstehend "das Unternehmen" oder "der Datenverantwortliche"), die diese Daten nach Maßgabe der einschlägigen geltenden Datenschutzbestimmungen verarbeitet.`,
  ol1: {
    title: `Navigationsdaten und deren Speicherung`,
    p1: `Die für den Betrieb dieser Website verwendeten IT-Systeme und Softwareverfahren erfassen während des normalen Betriebs einige Daten, die bei der Verwendung von Internet-Kommunikationsprotokollen automatisch übertragen werden.`,
    p2: `Diese Informationen werden nicht gesammelt, um mit identifizierten Interessenten verknüpft zu werden, aber sie könnten aufgrund ihrer Eigenschaften, auch durch die Verarbeitung mit Daten im Besitz von Dritten, die Identifizierung von Benutzern/Besuchern ermöglichen (z.B. IP-Adresse; Domain-Namen von Computern, die von Benutzern verwendet werden, die die Website besuchen; Zahlencode für die Angabe des Antwortstatus durch den Server; Browsertyp; Zeitangaben des Beginns und des Endes der Browsersitzung; eventuell Konto-ID und Angaben zu den auf der Website ausgeführten Aktivitäten usw.).`,
    p3: `Diese Daten werden nur zu statistischen Zwecken und zur Überwachung der korrekten Funktionsweise der Website verwendet. Die Daten selbst werden maximal sechs Monate lang gespeichert, soweit nicht eine längere Speicherdauer für die Untersuchung von IT-Straftaten gegen die Website oder zur Erfüllung spezifischer gesetzlicher Verpflichtungen erforderlich ist.`,
    p4: `Es werden keine Daten aus dem Webservice verbreitet.`
  },
  ol2: {
    title: `Definition von Cookies`,
    p1: `Cookies sind kleine Textdateien, die beim Nutzen von Internetseiten an die Endgeräte (meist an den Browser) der Besucher gesendet werden, wo sie gespeichert und dann bei jedem weiteren Besuch über dasselbe Endgerät wieder an dieselben Seiten übertragen werden. Darüber hinaus kann jede Website die Übertragung so genannter "Drittanbieter"-Cookies zulassen, also von Cookies, die von anderen Websites und nicht von der gerade besuchten Seite angelegt werden (durch darin vorhandene Objekte wie z.B. Banner, Bilder, Karten, Töne, spezifische Links zu Webseiten anderer Domains).`,
    p2: `Je nach Dauer wird zwischen Session-Cookies, also temporär gespeicherten Cookies, die am Ende der Browsersitzung durch Schließen des Browsers automatisch vom Endgerät gelöscht werden, und persistenten Cookies unterschieden, d.h. Cookies, die auf dem Endgerät gespeichert bleiben, bis sie ablaufen oder vom Benutzer gelöscht werden.`,
    p3: `Cookies haben mehrere Zwecke. In erster Linie dienen sie der Kommunikationsübermittlung oder der Erbringung des vom Benutzer gewünschten Service und ermöglichen und optimieren den Betrieb der Website.`,
    p4: `Diese Cookies werden als "technische" Cookies bezeichnet; für ihre Verwendung ist die Zustimmung des Benutzers nicht nötig, da ohne sie einige der oben genannten Vorgänge nicht durchgeführt werden könnten oder komplexer und/oder weniger sicher wären.`,
    p5: `Werden die Cookies hingegen für weitere Zwecke verwendet, etwa für die Analyse des Verhaltens und für das Versenden von personalisierten Werbemitteilungen (so genannte „profilierende" Cookies) oder auch nur, um über Dienste von Dritten Informationen in aggregierter Form über die Anzahl der Benutzer und deren Besuch der Website zu erhalten (so genannte "Analyse-Cookies"), ist die Zustimmung des Benutzers erforderlich. Denn bevor diese Cookies an das Endgerät gesendet werden, wird laut geltender Datenschutzregelung (Datenschutzgesetz und Allgemeine Vorschrift der Datenschutz-Aufsichtsbehörde vom 8. Mai 2014) beim Zugriff auf die Startseite oder eine andere Seite der Website im Vordergrund sofort ein Banner angezeigt. Damit werden bereits erste zusammenfassende Informationen über die Verwendung von Cookies und die Einholung der Zustimmung mitgeteilt. Der Benutzer kann seine Zustimmung z.B. durch Fortsetzung der Navigation erteilen, indem er auf ein Element unterhalb des Banners zugreift oder das Banner selbst schließt.`
  },
  ol3: {
    title: `Verwendung von Cookies auf dieser Website`,
    p1: `Das Unternehmen verwendet Cookies, um den Benutzern ein möglichst auf ihre Wünsche und Vorlieben abgestimmtes Surferlebnis zu bieten. Mit den Cookies sorgt das Unternehmen dafür, dass Sie nicht bei jedem Besucht der Website dieselben Informationen erhalten oder eingeben müssen. Cookies werden auch zur Optimierung der Performance der Website verwendet: Wenn sich der Benutzer über seinen Browser mit der Website verbindet, könnten Cookies auf seinem Gerät installiert werden; diese dienen der Erkennung des Geräts, gewährleisten die Nutzung der Website während der Gültigkeitsdauer der Cookies und sammeln Informationen über die Besuche der Website selbst.`,
    p2: `Um die eigenen personenbezogenen Daten vor einem möglichen Verlust und vor jeder Art von unrechtmäßigen Verarbeitung zu schützen, hat das Unternehmen angemessene technische und organisatorische Sicherheitsmaßnahmen getroffen.`,
    p3: `Beim Surfen auf der Website kann der Benutzer auf seinem Endgerät auch Cookies erhalten, die von anderen Websites oder Webservern (so genannte. "Drittanbieter“) gesendet werden, wenn auf der vom Benutzer besuchten Website Objekte (z.B. Bilder, Karten, Töne, spezifische Links zu Seiten anderer Domains) dieser anderen Seiten vorhanden sind. Es gibt also folgende Arten von Cookies: `,
    ol1: {
      li1: `First-Party-Cookies: Sie werden direkt vom Unternehmen an das Gerät des Benutzers gesendet. Diese Cookies, technische und Performance-Cookies, haben beschränkte Funktionen: Erstere übertragen die Kennung der Browsersitzung (Session-ID, bestehend aus vom Server generierten Zufallszahlen), die eine sichere und effiziente Navigation auf der Website ermöglicht. Performance-Cookies werden zu statistischen Zwecken verwendet, um einmalige Besucher, die angezeigten oder heruntergeladenen Inhalte usw. zu ermitteln. Ein Beispiel dafür ist Google Analytics.`,
      li2: `Cookies von Drittanbietern (third-party cookies), wie sie z. B. von der Facebook "Like"-Schaltfläche angelegt werden: Hier handelt es sich um Cookies, die von anderen Parteien als dem Betreiber der gerade vom Benutzer besuchten Website erzeugt und verwendet werden, und zwar auf der Basis von Verträgen zwischen dem Websitebetreiber und der betreffenden Drittpartei. Einige Werbetreibende verwenden diese Cookies, um die Besuche der Benutzer auf den Websites zu verfolgen, auf denen sie ihre Dienste anbieten.`
    },
    p4: `Sie können die Einstellungen in Ihrem Browser mit in einem sehr einfachen Verfahren so ändern, dass Cookies deaktiviert werden. Bitte beachten Sie, dass bei der Deaktivierung von Cookies Ihr Benutzername und Ihr Passwort nicht mehr in der Login-Box auf der Website gespeichert werden.`,
    p5: `Laut den in Italien geltenden Vorschriften ist für die Verwendung von Cookies nicht immer die ausdrückliche Zustimmung des Benutzers erforderlich. Keine Zustimmung braucht es insbesondere für "technische Cookies", also solche, die für das Funktionieren der Website unverzichtbar oder für die Durchführung der vom Benutzer gewünschten Aktivitäten notwendig sind.`,
    p6: `Je nach Verwendungszweck unterscheidet man folgende Cookies:`,
    ol2: {
      li1: `Technische Cookies: Ihre Verwendung ist strikt auf die Übertragung der Session-ID (vom Server generierte Zufallszahlen) beschränkt, die für die sichere und effiziente Erkundung der Website nötig ist. Diese Cookies werden nicht dauerhaft auf dem Computer des Benutzers gespeichert und verschwinden nach dem Schließen des Browsers. Der Einsatz solcher Cookies dient der Sicherheit und Verbesserung des angebotenen Dienstes. Das Unternehmen verwendet insbesondere die folgenden technischen Cookies:`,
      li2: `Analyse-Cookies: Das Unternehmen verwendet Analyse-Cookies, um die Anzahl der Benutzer und ihr Verhalten auf der Website zu analysieren, wobei diese Informationen in aggregierter Form gesammelt werden. Das Unternehmen verwendet insbesondere die folgenden Analyse-Cookies:`,
      li3: `Profilierende Cookies: Sie dienen der Erstellung von Benutzerprofilen und werden verwendet, um gezielte, auf die beim Surfen im Internet geäußerten Vorlieben abgestimmte Werbemitteilungen zu versenden oder das Surferlebnis zu verbessern. Solche Cookies können für diese Zwecke nur mit der Einwilligung des Benutzers verwendet werden.`
    },
    p7: `Nachfolgend finden Sie eine Beschreibung der auf der Website verwendeten profilierenden Cookies von Erst- und Drittanbietern.`
  },
  ol4: {
    title: `Verwalten von Cookies über Ihre Browsereinstellungen`,
    p1: `Der Benutzer kann auswählen, welche Cookies er über das im Abschnitt "Cookie-Einstellungen" beschriebene spezifische Verfahren zulassen möchte. Außerdem kann er Cookies über die spezifischen Funktionen seines Browsers oder durch die Verwendung spezifischer Software zulassen, blockieren oder (ganz oder teilweise) löschen: Wenn jedoch alle oder einige der Cookies deaktiviert werden, ist die Website möglicherweise nicht zugänglich oder einige Dienste oder Funktionen der Website sind möglicherweise nicht verfügbar oder funktionieren nicht ordnungsgemäß und/oder der Benutzer ist möglicherweise gezwungen, einige Informationen oder Einstellungen bei jedem Besuch der Website zu ändern oder manuell einzugeben. Weitere Informationen darüber, wie Sie über Ihren Browser die Verwendung von Cookies einstellen können, entnehmen Sie bitte den entsprechenden Anweisungen: `,
    ul1: {
      li1: `Internet Explorer: https://support.microsoft.com/it-it/help/17442/windows-internet-explorer-delete-manage-cookies`,
      li2: `Firefox: https://support.mozilla.org/it/kb/Attivare%2520e%2520disattivare%2520i%2520cookie`,
      li3: `Chrome: https://support.google.com/chrome/answer/95647?hl=it&topic=14666&ctx=topicc`,
      li4: `Safari: https://support.apple.com/kb/index?page=search&fac=all&q=cookies%2520safari`
    },
    p2: `Wir informieren Sie, dass Google (  
        https://support.google.com/analytics/answer/60042455
      ) Besuchern, die nicht möchten, dass ihre Surf-Informationen an Google Analytics gesendet werden, die Möglichkeit bietet, ein für die meisten modernen Browser verfügbares "Opt-Out-Browser-Add-on" zu installieren (siehe https://tools.google.com/dlpage/gaoptout), über das die Cookies von Google Analytics abgelehnt werden können. Werden bei der Nutzung der Website die Cookies von Google nicht abgelehnt, erklärt sich der Benutzer daher  - beschränkt auf die oben genannten Methoden und Zwecke - mit der Bearbeitung der über ihn erhobenen Daten durch Google einverstanden. Die Datenschutzinformation des Unternehmens Google für den Dienst Google Analytics kann man auf der Website http://www.google.com/analytics/learn/privacy.html einsehen.`,
    p3: `Mehr über die Datenschutzregeln von Google erfahren Sie auf der Internetseite http://www.google.com/intl/it/privacy/privacy-policy.html.`,
    p4: `Wenn Sie ein mobiles Gerät verwenden, erfahren Sie im Benutzerhandbuch, wie Sie Cookies verwalten können.`,
    p5: `Wenn der Benutzer den Besuch der Website nicht mit seinem Social-Media-Konto verknüpfen möchte, muss er sich ausloggen. Unter den nachstehenden Links werden die von den wichtigsten sozialen Netzwerken angebotenen Möglichkeiten dargelegt, wie Benutzer die Plugins für soziale Netzwerke deaktivieren können:`,
    ul2: {
      li1: `•	Facebook: Loggen Sie sich in Ihr Konto ein, Abschnitt Datenschutz`,
      li2: `	Twitter:
          https://twitter.com/settings/security`,
      li3: `	LinkedIn:
          https://www.linkedin.com/settings/`,
      li4: `Google+:
          http://www.google.it/intl/it/policies/technologies/managing/`
    },
    p6: `Weitere Informationen zur Verarbeitung und Nutzung von Daten über die soziale Netzwerke sowie zu den Datenschutzeinstellungen finden Sie in der Datenschutzrichtlinie der verschiedenen sozialen Netzwerke oder Websites. Nachstehend werden die betreffenden Links angeführt:`,
    ul3: {
      li1: `Facebook: https://www.facebook.com/help/cookies/`,
      li2: `	Twitter:
        https://support.twitter.com/articles/20170514`,
      li3: `	LinkedIn:
        https://www.linkedin.com/legal/cookie-policy`,
      li4: `Google+:
        http://www.google.it/intl/it/policies/technologies/cookies/`
    },
    p7: `Weitere Informationen über die Verarbeitung der personenbezogenen Daten des Benutzers finden Sie in der Datenschutzerklärung auf der Website.`
  },


  ol5: {
    title: `Modalitäten der Datenverarbeitung`,
    p1: `Die Verarbeitung erfolgt mit Hilfe von IT- und Telematikinstrumenten und/oder manuell (z.B. auf Papier) für die Zeit, die unbedingt zur Erreichung der Zwecke notwendig ist, für die die Daten erhoben werden, und in jedem Fall in Übereinstimmung mit den geltenden Vorschriften.`,
  },
  ol6: {
    title: `Freiwillige Bereitstellung von Daten`,
    p1: `Abgesehen von den Angaben zu den Navigationsdaten steht es den Benutzern/Besuchern frei, ihre persönlichen Daten zu nennen. Die Nichtbereitstellung dieser Daten hat nur zur Folge, dass die angeforderten Daten nicht beschafft werden können.`,
    p2: `Weitere Informationen zur Verarbeitung personenbezogener Daten und zu den Rechten des Benutzers finden Sie in der Datenschutzerklärung der Website.`
  }
}
