export default {
  userDropdown: {
    manageProfile: "Gestione account",
    manageConsents: "Gestione consensi",
    logout: "Log out",
  },
  privacyConsent: {
    description:
      "Preso atto dell’informativa di cui sopra, presto il consenso al trattamento dei miei dati per le seguenti finalità:",
  },
  signIn: "Registrati",
  signInDelegate: "Registra beneficiario finale",
  signInCompany: "Registra Operatore",
  dataCompany: "Dati studio",
  tabs: {
    profile: "Profilo",
    professional: "Dati Professionali",
    birth: "Dati di Nascita",
    address: "Indirizzo",
    skills: "Competenze",
  },
};
