export default {
  formActions: {
    cancel: "Annulla",
    reset: "Ripristina",
    submit: "Salva",
    submitAndClose: "Salva e Chiudi",
    submitNext: "Salva e Procedi",
    close: "Chiudi",
    abandon: "Abbandona",
    add: "Aggiungi",
    confirm: "Conferma",
    search: "Cerca",
    noFiscal: "Salta step"
  },
  personalData: {
    phone: "Telefono",
    cf: "C.F.",
    piva: "P.IVA",
    mail: "Email",
    subscriptionCCIA: "Nr. iscrizione CCIAA",
  },
  documentFileInfo: {
    fileName: "Nome File",
    uploadDate: "Data Caricamento",
  },
  dropZoneHint: "Carica oppure trascina qui i tuoi documenti",
  all: "Tutto",
  print: "Stampa",
  back: "Indietro",
  goBack: "Torna Indietro",
  error: "Errore",
  selectAll: "Seleziona tutti",
  select: "Seleziona...",
  other: "Altro",
  genders: {
    male: "M",
    female: "F",
  },
  retrieveEntityError: "Impossibile ottenere dati {{entity}}",
  successMessage: "Aggiornamento avvenuto con successo",
  insertMessage: "Inserimento avvenuto con successo",
  inviteUserMessage: "Utente Invitato con successo",
  errorMessage: "Impossibile eseguire l'operazione. Contattare l'assistenza",
  cfValidationError: "Codice Fiscale inserito non valido",
  updateEntitySuccess: "{{entity}} aggiornato con successo",
  newEntitySuccess: "{{entity}} creato con successo",
  updateEntityError: "Impossibile aggiornare {{entity}}",
  newEntityError: "Impossibile creare {{entity}}",
  submitEntityError: "Impossibile salvare {{entity}}",
  submitEntitySuccess: "Salvataggio {{entity}} effettuato",
  removeEntitySuccess: "Rimozione {{entity}} effettuata",
  submitSuccess: "Salvataggio effettuato",
  jobSuccess: "Operazione effettuata",
  checkDataError: "Ricontrolla i dati",
  emissary: "Beneficiario",
  emissaries: "Beneficiari",
  emissaryVariant: "Beneficiario",
  delegate: "Delegato",
  activity: "Intervento",
  practice: "Pratica",
  propertyData: "Dati Immobile",
  enterprise: "Impresa",
  profile: "Profilo",
  cession: "Cessione",
  operator: "Gestore",
  license: "Licenza",
  admin: "Amministratore",
  administration: "Amministrazione",
  user: "Utente",
  certTechnical: "Asseveratore tecnico",
  certFiscal: "Certificatore Fiscale",
  customer: "Cliente",
  transferer: "Cedente",
  market: "Advisor",
  deduction: "Detrazione"
};
