import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import loadReducer from "../library/elements/loader/loaderFullscreen/loadSlice";
import errorReducer from "../library/redux/errors/errorSlice";
import birthplaceReducer from "../library/redux/birthplace/birthplaceSlice";

export const store = configureStore({
  reducer: {
    load: loadReducer,
    errors: errorReducer,
    birthplace: birthplaceReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
