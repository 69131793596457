export default {
  general: {
    finishedWorks: "Lavori Finiti",
    startedWorks: "Lavori Iniziati",
    completed: "Completate",
    draft: "Bozze",
    completedItem: "Completata",
    draftItem: "Bozza",
    status: "Stato Pratica",
    practiceID: "ID Pratica",
    startDate: "Inizio Lavori",
    endDate: "Fine Lavori",
    offers: "Offerte",
    offersAccepted: "Offerte Accettate",
    enterpriseAdd: "Inserisci impresa lavori",
    enterpriseChoice: "Scegli impresa lavori",
    operatorAdd: "Inserisci Operatore",
    operatorChoice: "Scegli Operatore",
    certFiscalChoice: "Scegli Certificatore Fiscale",
    certFiscalAdd: "Inserisci Certificatore Fiscale",
    certTechChoice: "Scegli Asseveratore Tecnico",
    certTechAdd: "Inserisci Asseveratore Tecnico",
    interventiTrainati: 'Interventi Trainati',
    interventiTrainanti: 'Interventi Trainanti',
    interventi: "Interventi"
  },
  steps: {
    step2: "Intervento e Stato",
    step7: "Note",
    step8: "Documenti",
  },
  create: {
    newPractice: "Nuova Pratica",
    generalLabel: {
      fiscalCode: "Codice Fiscale",
      pIva: "P.IVA",
      profession: "Professione",
      name: "Nome",
      surname: "Cognome",
      gender: "Sesso",
      email: "E-mail",
      pec: "PEC",
      telephone: "Telefono",
      birthdatas: "Dati di nascita",
      birthdata: "Data di nascita",
      state: "Nazione",
      province: "Provincia",
      municipality: "Comune",
      locality: "Località",
      address: "Indirizzo",
      street: "Via",
      cap: "CAP",
      private: "Privato",
      condominium: "Condominio",
      legalPerson: "Persona giuridica",
      businessName: "Ragione sociale",
      legalSpecies: "Specie giuridica",
      subjectTipology: "Tipologia soggetto",
      role: "Ruolo",
      competencies: "Competenze",
      cv: "Curriculum",
      reference: "Referenze",
      intervento: "Intervento",
      noIntervento: "Nessun intervento disponibileo",
      category: "Categoria",
      propertyType: "Tipo Immobile",
      cancelConfirmDialog: {
        title: "Sei sicuro di voler abbandonare la pratica?",
        subtitle: "Troverai la pratica tra le bozze",
      },
    },
    customer: {
      customerDatas: "Dati cliente",
      denomination: "Denominazione",
      asDelegate: "Uguale al Delegato",
      asEmissary: "Uguale al Beneficiario",
      confirmOverwritePrivate: {
        title: "Conferma Richiesta",
        subtitle:
          "Sicuro di voler sovrascrivere i dati del beneficiario con il delegato?",
      },
      enableDelegateError:
        "Si è verificato un errore nell'attivazione del delegato",
      enableDelegateSuccess: "Delegato attivato con successo",
      newDelegateSuccess: "Delegato creato con successo",
      updateDelegateSuccess: "Delegato aggiornato con successo",
      overwriteCustomerDialogConfirm: {
        title: "Sovrascriviamo i dati del delegato?",
        subtitle:
          "Carichiamo i dati del delegato dal mandatario appena scelto?",
      },
    },
    operation: {
      subtitle: "Seleziona intervento",
      statusPractice: "Stato pratica",
      expectedDateEndWork: "Data prevista fine lavori",
      dateStartWork: "Data inizio lavori",
      dateEndWork: "Data fine lavori",
      amountSpent: "Importo Spesa",
      percentageProgressWork: "Percentuale avanzamento lavori",
      totalTaxCredit: "Totale credito d'imposta",
      discountInvoice: "Sconto in fattura",
      amountDiscountInvoice: "Importo scontato in fattura",
      creditTransfer: "Cessione credito",
      choose: "Scegli",
      filter: "Filtra",
      agreed: "Accordato",
      requested: "Richiesto",
      aeInstructions: "Istruzioni modulo AE",
      no_operation_selected: "Nessun Intervento selezionato",
      changeDeductionDialog: {
        title: "Attenzione!",
        subtitle:
          "Cambiando la detrazione si perderanno tutte le informazioni degli interventi, del certificatore fiscale, dell'asseveratore tecnico e i documenti già inseriti",
      },
      deductionTypes: {
        SUPERBONUS_110: "SUPERBONUS 110%",
        BONUS_FACCIATE: "BONUS FACCIATE",
        SISMABONUS: "SISMABONUS",
        INF_RIC_VEICOLI_ELETTRICI: "INFRASTRUTTURA RICARICA VEICOLI ELETTRICI",
        RECUPERO_PATRIMONIO_EDILIZIO: "RECUPERO PATRIMONIO EDILIZIO",
        ECOBONUS: "ECOBONUS",
        BONUS_VERDE: "INSTALLAZIONE IMPIANTI FOTOVOLTAICI",
        ECO_SISMABONUS: "ECO-SISMABONUS",
        SISMABONUS_110: "SISMABONUS 110%",
      },
      operationTypes: {
        "tipo_-1": "Eliminazione barriere architettoniche",
        tipo_1:
          "Intervento di isolamento termico delle superfici opache verticali, orizzontali o inclinate che interessano l'involucro dell'edificio con un'incidenza superiore al 25%",
        tipo_2:
          "Intervento per la sostituzione degli impianti di climatizzazione invernale esistenti",
        tipo_3: "Riqualificazione energetica di edificio esistente",
        tipo_4: "Coibentazione involucro",
        tipo_5: "Acquisto e posa in opera di finestre comprensive di infissi",
        tipo_6:
          "Sostituzione impianti di climatizzazione invernale con caldaie a condensazione Classe A",
        tipo_7:
          "Intervento di sostituzione di impianti di climatizzazione invernale con caldaie a condensazione classe A+ sistemi di termoregolazione o con generatori ibridi o con pompe di calore; intervento di sostituzione di scaldacqua",
        tipo_8: "Installazione di pannelli solari su edificio esistente",
        tipo_9: "Acquisto e posa in opera di schermature solari",
        tipo_10:
          "Sostituzione impianti di climatizzazione invernale con generatori di calore alimentati a biomasse combustibili",
        tipo_11:
          "Acquisto e posa in opera di micro-cogeneratori in sostituzione di impianti esistenti",
        tipo_12:
          "Acquisto e installazione dispositivi multimediali per il controllo da remoto",
        tipo_13: "Intervento antisismico in zona sismica 1,2 e 3",
        tipo_14:
          "Intervento antisismico in zona sismica 1,2 e 3 con passaggio a 1 classe inferiore",
        tipo_15:
          "Intervento antisismico in zona sismica 1,2 e 3 con passaggio a 2 classi inferiori",
        tipo_16:
          "Intervento ai fini del risparmio energetico basato su fonti di energia rinnovabili",
        tipo_17:
          "Intervento di manutenzione straordinaria, restauro e recupero conservativo o ristrutturazione edilizia e intervento di manutenzione ordinaria effettuato sulle parti comuni di un edificio",
        tipo_18:
          "Intervento di recupero o restauro della facciata di edifici esistenti",
        tipo_19:
          "Intervento per l'installazione di impianti solari fotovoltaici connessi alla rete elettrica su edifici",
        tipo_20:
          "Intervento per l'installazione contestuale o successiva di sistemi di accumulo integrati negli impianti solari fotovoltaici agevolati",
        tipo_21:
          "Intervento per l'installazione di colonnine per la ricarica di veicoli elettrici negli edifici",
        tipo_22:
          "CONDOMINI: Coibentazione involucro con superficie interessata superiore al 25% della superfice disperdente",
        tipo_23:
          "CONDOMINI: Coibentazione involucro con superficie interessata superiore al 25% della superfice disperdente e conseguimento della qualità media dell'involucro",
        tipo_24:
          "CONDOMINI: coibentazione involucro con superficie interessata superiore al 25% della superfice disperdente e riduzione di 1 classe del rischio sismico",
        tipo_25:
          "CONDOMINI: coibentazione involucro con superficie interessata superiore al 25% della superfice disperdente e riduzione di 2 classi del rischio sismico",
        tipo_26:
          "Acquisto di un'unità immobiliare antisismica per passaggio a 1 classe inferiore",
        tipo_27:
          "Acquisto di un'unità immobiliare antisismica e interventi per passaggio a 2 classi inferiori",
      },
    },
    property: {
      propertyTitle: "Dati Immobile",
      singleFamily: "Unifamiliare",
      plurifamily: "Indipendente in edificio plurifamiliare",
      condominium: "Parti comuni in edificio condominiale",
      singoloincondominio: "Unità immobiliari singole in edificio condominiale",
      edificiVincolati: "Edifici vincolati",
      estateUnits: "Unità immobiliari",
      categoryCatastal: "Categoria catastale",
      sheet: "Foglio",
      particle: "Particella",
      subordinate: "Subalterno",
      buildingTypes: {
        A_1: "A/1 - Abitazioni di tipo signorile",
        A_2: "A/2 - Abitazioni di tipo civile",
        A_3: "A/3 - Abitazioni di tipo economico",
        A_4: "A/4 - Abitazioni di tipo popolare",
        A_5: "A/5 - Abitazioni di tipo ultrapopolare",
        A_6: "A/6 - Abitazioni di tipo rurale",
        A_7: "A/7 - Abitazioni in villini",
        A_8: "A/8 - Abitazioni in ville",
        A_9: "A/9 - Castelli, palazzi di eminenti pregi artistici e storici",
        A_10: "A/10 - Uffici e studi privati",
        A_11: "A/11 - Abitazioni ed alloggi tipici dei luoghi",
      },
    },
    enterprise: {
      activity: "Attività",
      numberSigningCCIAA: "Nr. iscrizione CCIAA",
      of: "di",
      referentData: "Dati referente",
      title_1: "Impresa Lavori",
      title_2: "Stato Lavori",
      chooseActivity:
        "Seleziona il tipo di intervento e procedi con l'inserimento dell'impresa lavori",
    },
    certTechnical: {
      isRegisteredAlbo: "Iscritto all'albo",
      registrationAlbo: "Iscrizione all'albo",
      college: "All'ordine/collegio professionale",
      of: "di",
      numberRegistration: "Numero di iscrizione",
      certificate: "Certificato",
      insurance: "Assicurazione RC professionale tecnico",
      IsInsurance: "Assicurato",
      numbererInsurance: "Nr. polizza",
      companyInsurance: "Compagnia assicuratrice",
      authorisedBy: "Autorizzato da",
      amountInsurance: "Importo assicurato",
      title: "Asseveratore tecnico",
      select: "Seleziona Operatore",
      asEmissary: "Uguale al Beneficiario"
    },
    certFiscal: {
      isRegisteredAlbo: "Iscritto all'albo",
      registrationAlbo: "Iscrizione all'albo",
      college: "All'ordine/collegio professionale",
      of: "di",
      numberRegistration: "Numero di iscrizione",
      certificate: "Certificato",
      title: "Certificatore fiscale",
      select: "Seleziona Operatore",
    },
    note: {
      info: "Informazioni aggiuntive",
      placeholder: "Inserisci eventuali note",
      enterpriseNotes: "Note impresa lavori",
      fiscalNotes: "Note Certificatore Fiscale",
      techNotes: "Note Asseveratore tecnico",
    },
    documents: {
      documentList: "Documentazione",
      missingDocs:
        "Nessun documento da mostrare. Accertati di aver selezionato una detrazione valida",
    },
    praticaAE: {
      subtitle: "Modello Trasmissione Pratica Agenzia Entrate",
      firma: "Conferma i dati",
      superbonusonly: "Da compilare solo in presenza di superbonus",
      submitCompleted:
        "Salvataggio pratica superbonus completata con successo. Scarica il file da importare in desktop telematico Agenzia Entrate",
      buttons: {
        model: "Modulo",
        transmission: "Trasmissione",
      },
      dati_preliminari: {
        title: "Seleziona un Beneficiario valido",
        tipo_soggetto: "Tipo Beneficiario",
        tipo_soggetto_options: {
          tipo_1: "1. Singolo Beneficiario",
          tipo_2: "2. Condominio",
        },
        subtitle:
          "A seconda del tipo beneficiario vengono mostrati i campi obbligatori da compilare",
      },
      beneficiario: {
        title: "Dati del Beneficiario",
        rappresentante_beneficiario: {
          title: "Dati Relativi al Rappresentante Beneficiario",
          subtitle:
            "Da compilare solo se il firmatario della comunicazione è un soggetto diverso dal beneficiario",
          cod_carica: "Codice Carica",
        },
      },
      condominium: {
        title: "Condominio",
        subtitle:
          "Da compilare solo in presenza di interventi effettuati sulle parti comuni di un edificio",
        min: "Condominio Minimo",
        cfAdmin: "C.F. amministratore/incaricato",
      },
      incaricato: {
        title:
          "Impegno alla Presentazione Telematica - Riservato all'incaricato",
        data: "Data dell'impegno",
      },
      visto_conformita: {
        title:
          "Visto di Conformità - Riservato al C.A.F. o al professionista abilitato",
        cf_responsabile_caf: "C.F. Responsabile C.A.F.",
        cf_caf: "C.F. C.A.F.",
        cf_professionista: "C.F. Professionista",
        firma_digitale: "Firma del responsabile C.A.F. o del professionista",
      },
      asseverazione_efficienza_energetica: {
        title: "Asseverazione efficienza energetica",
        ci_enea: "Codice Identificativo ENEA",
        polizza_assicurativa: "Polizza Assicurativa",
      },
      asseverazione_rischio_sismico: {
        title: "Asseverazione Rischio Sismico",
        ci_asseverazione: "Codice Identificativo Asseverazione",
        cf_professionista: "C.F. Professionista",
        polizza_assicurativa: "Polizza Assicurativa",
      },
      quadro_a: {
        title: "Quadro A - Intervento",
        tipologia_intervento: "Tipo intervento",
        intervento_superbonus: "Superbonus",
        intervento_immobile_con_restrizione: "Immobile con restrizioni",
        unita_condominio: "Unità condominio",
        spesa: "Spesa €",
        anno_spesa: "Anno spesa",
        periodo_anno: "Periodo 2020",
        sal: "Stato Avanzamento Lavori",
        prot_telematico_prima_comunicazione:
          "Prot. telematico trasm. 1ª comunicazione",
        prog_telematico_prima_comunicazione:
          "Progressivo telematico trasm. 1ª comunicazione",
        primo_anno_spesa: "1° anno sostenimento spesa",
      },
      quadro_b: {
        title:
          "Quadro B - Dati Catastali Identificativi dell'immobile oggetto dell'intervento",
        subtitle:
          "Puoi modificare il numero di righe impostando il numero di unità condominio dal Quadro A",
        cod_comune: "Codice Comune",
        tipologia_immobile: "Tipo",
        sez_urbana: "Sez. Urbana/Comune Cat.",
        foglio: "Foglio",
        particella_numeratore: "Part. Numeratore",
        particella_denominatore: "Part. Denominatore",
        subalterno: "Subalterno",
        id_immobile: "ID",
      },
      quadro_c: {
        title: "Quadro C - Opzione",
        sezione_1: {
          title: "Sezione 1 - Beneficiario",
          tipo: "Tipo Opzione",
          tipo_a: "Contributo Sotto forma di Sconto",
          tipo_b: "Cessione del credito d'Imposta",
          importo_credito_ceduto: "Credito ceduto o contributo €",
          rate_residue_credito_ceduto: "Credito ceduto (rate residue) €",
          rate_residue: "N. rate residue",
          termine_presentazione: "Termine Presentazione",
        },
        sezione_2: {
          title: "Sezione 2 - Soggetti Beneficiari",
          subtitle:
            "Questa sezione va compilata solo in presenza di interventi effettuati sulle parti comuni di un edificio",
          tipo: "Tipo Opzione",
          spesa: "Spesa €",
          credito_ceduto: "Credito Ceduto €",
          id_beneficiario: "ID Benef.",
          id_immobile: "ID Imm.le",
        },
      },
      quadro_d: {
        title:
          "Quadro D - Dati dei cessionari o dei fornitori che applicano lo sconto",
        subtitle:
          "Il quadro va compilato solo se è intervenuta l’accettazione da parte del soggetto indicato",
        beneficiarioSingoloHint:
          "Il Campo 'ID Beneficiario' deve essere pari a 1 per ogni rigo in caso di Tipo Beneficiario 'Singolo Beneficiario'",
        cf: "C.F. Cessionario/Fornitore",
        data: "Data di esercizio",
        credito_ceduto: "Credito Ceduto €",
        tipologia_cessionario: "Tipo Cessionario",
        id_beneficiario: "ID Beneficiario",
        tipologie: {
          tipo_1: "1.Fornitore",
          tipo_2: "2.Privato/Intermediario finanziario",
          tipo_3: "3.Impresa Assicurativa",
        },
      },
    },
    praticaAETransmission: {
      title: "Trasmissione Pratica Agenzia Entrate",
      step1: {
        title:
          "Scarica il file di record da caricare su desktop telematico di Agenzia Entrate",
        subtitle: "File Record Agenzia Entrate (Formato: .txt)",
      },
      step2: {
        title: "Carica file generato da desktop telematico di Agenzia Entrate",
        noFile: "Nessun File Caricato (Formati supportati: *.dcm)",
      },
      step3: {
        title: "Conferma Trasmissione",
        subtitle:
          "Inserisci Codice Protocollo Conferma Trasmissione ricevuto da Agenzia Entrate",
        prot_telematico_trasmissione: "Protocollo Telematico Trasmissione",
      },
    },
    praticaAEStatus: {
      title: "Gestione dello Stato Trasmissione della Pratica",
      step1: {
        title: "Stato Attuale di Trasmissione della Pratica",
      },
      step2: {
        title: "Modifica Stato di Trasmissione della Pratica",
        newstatus: "Nuovo Stato",
        notes: "Note (facoltativo)",
      },
      statuses: {
        undefined: "Sconosciuto",
        Sent: "Pec Inviata",
        Working: "In Lavorazione",
        ActionRequired: "Azione Richiesta",
        Canceled: "Annullata",
        Finished: "Completata",
      },
    },
    praticaAEList: {
      interventiList: "Gli interventi selezionati precedentemente sono:",
      editedIn: "Modificato il",
      completedListTitle: "Pratiche Completate",
      draftListTitle: "Pratiche Non Completate",
      draftStatuses: {
        noDCM: "Manca dcm",
        draft: "Bozza",
        notSent: "Manca Protocollo",
      },
      emptyCompletedList: "Niente da mostrare qui",
      emptyDraftList:
        "Niente da mostrare qui. Accertati di avere interventi da comunicare ad Agenzia Entrate",
      sal: "SAL",
    },
  },
  cessioneCredito: {
    missingCredit: "Nessun Credito a favore",
    maxCredit: "Massima detrazione",
    maxCreditSal: "Detrazione disponibile",
    aloneInMarket: "Vuoi andare sul mercato da solo?",
    marketSubmit: "Invia al Mercato",
    proceedTomarketSubmit: "Procedi al Mercato",
    sendToMarketSubmitDialog: {
      title: "Conferma invio mercato",
      subtitle: "Sei sicuro di procedere con l'invio dei dati al mercato?",
    },
    intervento: {
      importoSpesa: "Importo spesa",
      dataSpesa: "Data Spesa",
      creditoImposta: "Credito di imposta",
      note: "Note e Motivazioni",
      detraibileIn: "Detraibile in",
      nrRateResidue: "Numero rate residue",
      importoCreditoResiduo: "Importo credito residuo",
      cessioneCredito: "Cessione Credito",
      scontoFattura: "Sconto in fattura",
      dataEsercizio: "Data esercizio opzione",
      numeroProtocolloTelematico: "Protocollo telematico Ident.",
      progressivo: "Progressivo",
      rateResidue: "Rate Residue",
      creditoResiduo: "Credito Residuo",
      nominalValue: "Valore Nominale",
      startYear: "Anno Inizio",
      endYear: "Anno Fine",
      tipo: "Cessione/Sconto",
      stato: {
        SF: "Sconto in Fattura",
        CC: "Cessione Credito",
      },
    },
    attentionMsg: {
      label: "Attenzione!",
      msg: "Hai inserito un credito di imposta maggiore al credito massimo.",
    },
  },
  tabs: {
    summary: "Riepilogo",
    ae: "Agenzia Entrate",
    credit: "Crediti di Imposta",
  },
  errors: {
    title: "Elementi non trovati",
    summary: {
      no_property: "Non è stato inserito l'immobile",
      no_practices_id: "La pratica non è stata registrata correttamente",
      no_delegate: "Manca il cliente",
      no_operations: "Mancano interventi",
      no_enterprise: "Manca l'impresa lavori per uno o più interventi",
      no_cert_tecnical: "Non è stato inserito l'asseveratore tecnico",
      no_cert_fiscal: "Non è stato inserito il certificatore fiscale",
      no_state_work: "Non è stato inserito lo stato lavori per un intervento",
    },
  },
};
