import React from "react";
import { mergeClass } from "../../functions/function";
import { FormControlProps } from "react-bootstrap/FormControl";
import { FormControl } from "react-bootstrap";
import MultiSelectCustom from "./components/MultiSelectCustom";

interface FormProps extends FormControlProps {
	error?: boolean;
	options?: { label: string; value: any }[];
	multiply?: boolean;
	autocompleteSingleSelect?: boolean;
	changeSelect?: (value?: any) => void;
	value?: any;
}

const Select: React.FC<FormProps & React.HTMLProps<HTMLInputElement>> = ({
	children,
	className,
	options = [],
	error = false,
	multiply = false,
	autocompleteSingleSelect = false,
	changeSelect = () => {},
	value,
	...rest
}) => {
	const classMerge: string = error ? " error" : "";

	return (
		<>
			{multiply ? (
				<MultiSelectCustom
					disableMultipleSelect={autocompleteSingleSelect}
					className={mergeClass(classMerge, className)}
					options={options}
					value={value}
					changeSelect={changeSelect}
				/>
			) : (
				<FormControl
					custom
					as="select"
					value={value !== undefined && value !== "-1" ? value : "-1"}
					onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
						changeSelect(
							event.target.value !== "-1" ? event.target.value : undefined
						)
					}
					className={mergeClass(classMerge, className)}
					{...rest}>
					{options.map((opt, index) => (
						<option key={opt.value} value={opt.value}>
							{opt.label}
						</option>
					))}
				</FormControl>
			)}
		</>
	);
};

export default Select;
