export default {
  formActions: {
    cancel: 'Annullieren',
    reset: 'Wiederherstellen',
    submit: 'Speichern',
    submitAndClose: 'Speichern und schließen',
    submitNext: 'Speichern und weitergehen',
    close: 'Schließen',
    abandon: 'Verlassen',
    confirm: "Bestätigung",
    noFiscal: 'Schritt überspringen',
    add: 'Hinzufügen',
  },
  personalData: {
    phone: 'Telefon',
    cf: 'Steuernummer',
    piva: 'Mw.-st',
    mail: 'Email',
    subscriptionCCIA: 'Nr. CCIAA Anmeldung',
  },
  documentFileInfo: {
    fileName: 'Filename',
    uploadDate: 'Datum des Hochladens',
  },
  all: 'Alle',
  print: 'Drucken',
  back: 'Zurück',
  goBack: 'Zurückgehen',
  error: 'Fehler',
  selectAll: 'Alles unterstreichen',
  select: 'Wählen',
  retrieveEntityError: 'Daten können nicht abgerufen werden {{entity}}',
  successMessage: 'Erfolgreich Aktualisierung',
  errorMessage:
    'Die Operation konnte nicht ausgeführt werden. Kontaktieren Sie dei Assistenz',
  cfValidationError: 'Ungültiges Steuernummer eingegeben',
  updateEntitySuccess: '{{entity}} erfolgreich Aktualisiert',
  newEntitySuccess: '{{entity}} erfolgreich erstellt',
  updateEntityError: 'Aktualisierung {{entity}} nicht möglich',
  newEntityError: 'Unmögliche Erstellung {{entity}}',
  submitEntityError: 'Speichern unmöglich {{entity}}',
  submitEntitySuccess: '{{entity}} erfolgreich gespeichert',
  removeEntitySuccess: 'Entfernung {{entity}} durchgeführt',
  submitSuccess: 'Erfolgreich gespeichert',
  jobSuccess: 'Operation durchgeführt',
  checkDataError: 'Daten überprüfen',
  emissary: 'Bevollmächtigter',
  emissaries: 'Bevollmächtigen',
  emissaryVariant: 'Auftraggeber',
  delegate: 'Delegierte',
  activity: 'Intervent',
  practice: 'Modell für die Reife der Steuergutschrift',
  propertyData: 'Immobiliendaten',
  enterprise: 'Unternehm',
  profile: 'Profil',
  cession: 'Übertragung',
  operator: 'Verwalter',
  license: 'Lizenz',
  admin: 'Administrator',
  administration: 'Verwaltung',
  user: 'Benutzer',
  certTechnical: 'Technische Zertifizierer',
  certFiscal: 'Steuerzertifizierer',
  customer: 'Kunde',
  transferer: 'Zedent',
  market: 'Advisor',
  genders: {
    male: 'M',
    female: 'W',
  },
  deduction: 'Steuerabsetzung',
  dropZoneHint: 'Dokumente hochladen oder per Drag & Drop hier einfügen',
};
