import React from "react";
import MultiSelect from "react-multi-select-component";
import { FormControlProps } from "react-bootstrap/FormControl";
import { useTranslation } from "react-i18next";

interface FormProps extends FormControlProps {
	error?: boolean;
	options?: { label: string; value: any }[];
	multiply?: boolean;
	changeSelect?: (value?: any) => void;
	value?: any;
	disableMultipleSelect?: boolean;
}

const MultiSelectCustom: React.FC<
	FormProps & React.HTMLProps<HTMLInputElement>
> = ({
	children,
	className,
	options = [],
	error = false,
	multiply = false,
	changeSelect = () => {},
	value = [],
	disableMultipleSelect = false,
	...rest
}) => {
	const { t } = useTranslation();

	let valueMulti = options.filter((ele) => value.indexOf(ele.value) !== -1);
	let changeHandler = (nextValues: { label: string; value: any }[]) => {
		const nextSelectedValues = (disableMultipleSelect
			? nextValues.filter((nextValue: any) => !valueMulti.includes(nextValue))
			: nextValues
		).map((ele) => ele.value);

		changeSelect(nextSelectedValues);
	};
	return (
		<MultiSelect
			className="custom-select-multi"
			selectAllLabel={t("general.selectAll")}
			options={options}
			value={valueMulti}
			onChange={changeHandler}
			labelledBy={t("general.select")}
			hasSelectAll={!disableMultipleSelect}
			overrideStrings={{
				selectSomeItems: t("general.select"),
				allItemsAreSelected: "Tutti",
				selectAll: t("general.selectAll"),
				search: t("general.formActions.search"),
				clearSearch: "Pulisci ricerca",
			}}
		/>
	);
};

export default MultiSelectCustom;
