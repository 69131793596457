import React, { useState } from "react";
import { Navbar, Image, Nav, NavDropdown } from "react-bootstrap";
import Container from "../../../library/elements/grid/container/Container";
import logo from "../../../assets/logo.svg";
import { useRoute } from "../../../library/utils/routes/useRoute";
import useWellKnown from "../../../library/auth/wellKnown";
import LanguageSelector from "../../../library/elements/languageSelector/LanguageSelector";
import i18n, { dropdownSelectableLangs } from "../../../config/i18n";
import { useTranslation } from "react-i18next";

interface HeaderProps {}
const Header = ({}: HeaderProps) => {
  const { t } = useTranslation();
  const { changeRoute } = useRoute();
  const wellKnown = useWellKnown();

  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const changeLanguage = (lng: string) => {
    setSelectedLanguage(lng);
    i18n.changeLanguage(lng);
    window.location.reload();
  };
  return (
    <header>
      <Navbar
        fixed="top"
        collapseOnSelect
        expand="lg"
        bg="white"
        variant="light"
        className="navBrand"
      >
        <Container>
          <Navbar.Brand onClick={() => changeRoute("")} className="pointer">
            <Image
              src={wellKnown?.metadata?.logo_eco || logo}
              width="133px"
              className="logoBrand"
              alt="logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />

          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto menuItems"></Nav>

            {/* <NavDropdown
              title={t("profile.signIn")}
              id="collasible-nav-dropdown"
            >
              <NavDropdown.Item onClick={() => changeRoute("signInDelegate")}>
                {t("profile.signInDelegate")}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => changeRoute("signInCompany")}>
                {t("profile.signInCompany")}
              </NavDropdown.Item>
            </NavDropdown> */}
          </Navbar.Collapse>

          <Navbar.Brand>
            {wellKnown?.metadata?.logo && (
              <Image
                src={wellKnown?.metadata?.logo}
                width="133px"
                className="mx-4"
              />
            )}
          </Navbar.Brand>

          <Navbar>
            <LanguageSelector
              selectedLanguage={selectedLanguage}
              onSelectedLanguageChange={changeLanguage}
              availableLanguages={dropdownSelectableLangs}
            />
          </Navbar>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
