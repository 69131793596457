export default {
  title: 'Privacy Policy',
  subTitle: `Informativa sul trattamento dei dati personali ai sensi dell'art. 13 del Regolamento (UE) n. 2016/679`,
  p1: `La vigente normativa in materia di trattamento dei dati  personali definita in conformità alle previsioni contenute nel Regolamento UE 2016/679 del 27 aprile 2016 relativo alla protezione delle persone fisiche con riguardo al trattamento dei dati personali, nonché alla libera circolazione di tali dati (Regolamento generale sulla protezione dei dati, di seguito 'Regolamento UE' o 'GDPR') e nel D.lgs. n. 196/2003, come modificato dal D.Lgs. n. 101/2018, contiene disposizioni dirette a garantire che il trattamento dei dati personali si svolga nel rispetto dei diritti e delle libertà fondamentali delle persone fisiche, con particolare riguardo al diritto alla protezione dei dati personali.`,
  p2: `La presente informativa, resa ai sensi dell'articolo 13 del Regolamento UE 2016/679 (in seguito Regolamento), è rivolta agli utenti che si registrano sul sito globalbonus.flyecobonus.it per la gestione della procedura degli incentivi per la riqualificazione energetica del patrimonio edilizio esistente.`,
  ol1: {
    title: `Il Titolare`,
    p1: `Il Titolare del trattamento è TechVisory srl con sede in Via
    Kufstein 5, 38121 Trento.`
  },
  ol2: {
    title: `Il Responsabile della protezione dei dati`,
    p1: `Al fine di offrirTi un agevole punto di contatto, il
    Titolare ha designato un proprio Responsabile della
    Protezione dei dati personali ("DPO"), che
    può essere contattato al seguente indirizzo e-mail
    dpo@techvisory.it per
    tutte le questioni relative al trattamento dei Tuoi dati
    personali e all'esercizio dei Tuoi diritti, che potranno
    essere esercitati ai sensi del GDPR e secondo le modalità̀
    descritte nella sezione "Diritti dell'interessato" della
    presente Informativa.`
  },
  ol3: {
    title: `Finalità del trattamento dei dati`,
    p1: `I dati personali che la riguardano, a parte quanto
    specificato per i dati di navigazione, saranno gestiti in
    maniera conforme alle specifiche disposizioni di legge sopra
    richiamate, nel rispetto dei principi di liceità,
    correttezza, trasparenza, adeguatezza, pertinenza e
    necessità di cui all'art. 5, paragrafo 1 del GDPR, e saranno
    trattati per la gestione della procedura di riqualificazione
    energetica:`,
    p2: `Il conferimento dei dati per le finalità di cui sopra è
    obbligatorio. La mancanza dei dati e/o l'eventuale espresso
    rifiuto al trattamento comporterà l'impossibilità per il
    Titolare di svolgere il servizio.`,
    p3: `I Suoi dati personali, se necessario, potranno essere
    comunicati:`,
    ul1: {
      li1: `ai soggetti a cui la comunicazione dei dati debba essere
      effettuata in adempimento di un obbligo previsto dalla
      legge, da un regolamento o dalla normativa dell'Unione
      Europea, ovvero per adempiere ad un ordine dell'Autorità
      Giudiziaria;`,
      li2: `ai soggetti designati dal Titolare, in qualità di
      Responsabili, ovvero alle persone autorizzate al
      trattamento dei dati personali che operano sotto
      l'autorità diretta del Titolare o del Responsabile;`,
      li3: `una lista completa ed aggiornata dei Responsabili del
      trattamento nominati dal Titolare può essere ottenuta
      contattando il Titolare all'indirizzo indicato;`,
      li4: `ad altri eventuali soggetti terzi, quali enti pubblici o
      autorità istituzionali, nei casi espressamente previsti
      dalla legge, ovvero ancora se la comunicazione si renderà
      necessaria per la tutela della società TechVisory srl in
      sede giudiziaria, nel rispetto delle vigenti disposizioni
      in materia di protezione dei dati personali.`
    },
    p4: `Con il suo specifico e libero consenso i suoi dati potranno
    essere trattati per le ulteriori finalità:`,
    ol1: {
      li1: `Per marketing diretto, ossia per l'invio di materiale
      pubblicitario/informativo, di comunicazione commerciale,
      iniziative promozionali, per il compimento di ricerche di
      mercato o di soddisfazione del servizio erogato, con
      modalità interattive, e "tradizionali" (a titolo
      esemplificativo, posta cartacea e/o chiamata con
      operatore) o mediante sistemi "automatizzati" di contatto
      (a titolo esemplificativo SMS e/o MMS, chiamate
      telefoniche senza l'intervento dell'operatore, e-mail,
      fax, applicazioni interattive), da parte del Titolare.
      Tuttavia, l'interessato è informato che secondo quanto
      previsto dall'Autorità Garante per la protezione dei dati
      personali in virt ù di specifico Provvedimento del Garante
      n. 330 del 4 luglio 2013 (c.d. soft spam) l'indirizzo di
      posta elettronica fornito nell'ambito dei rapporti
      contrattuali in essere con gli autonomi Titolari degli
      stessi, raggiungibili per il tramite del presente Portale,
      potrà essere da queste utilizzato per l'invio di offerte
      commerciali relative a prodotti e/o servizi analoghi a
      quelli in precedenza acquistati. L'interessato potrà in
      ogni momento opporsi al trattamento esercitando il proprio
      diritto.`,
      li2: `Per l'analisi della propensione all'utilizzo di prodotti e
      servizi offerti dalla Società la definizione di profili
      individuali e di gruppo, la proposizione di offerte
      individuali predisposte anche mediante l'impiego di
      strumenti ed applicativi atti a rilevare le preferenze di
      consumo e l'analisi anche in forma personalizzata,
      automatizzata od elettronica, delle informazioni acquisite
      attraverso l'utilizzo dei prodotti e/o servizi utilizzati,
      pertanto solo previo suo consenso o qualora sia necessario
      per la conclusione o l'esecuzione del contratto potrà
      esser soggetto a tecniche di profilazione`,
      li3: `per la comunicazione di dati a soggetti terzi per finalità
      di marketing, relativamente a Istituti Bancari,
      Intermediari Finanziari e Assicurazioni`
    },
    p5: `La base giuridica per effettuare il trattamento e realizzare
    le finalità di cui ai precedenti punti risiede nel Suo
    consenso che dovrà essere libero, specifico, informato ed
    inequivocabile. Il conferimento dei Dati per il
    perseguimento delle finalità di cui ai precedenti punti è
    facoltativo e l'eventuale rifiuto di fornirli non comporterà
    conseguenze circa l'esecuzione del rapporto contrattuale. Il
    consenso da Lei prestato per tali finalità potrà essere
    revocato in qualsiasi momento.`
  },
  ol4: {
    title: `Tipologia di dati trattati`,
    p1: `	TechVisory srl in qualità di Titolare del trattamento,
    raccoglie le seguenti categorie di dati:`,
    p2: `Dati anagrafici`,
    ul1: {
      li1: `dati anagrafici del beneficiario o dell'intermediario
      raccolti in fase di registrazione al sito (o piattaforma)
      globalbonus.flyecobonus.it e nella fase immediatamente
      successiva, compri i dati di "contatto", ossia numero di
      telefono e indirizzo e-mail;`,
      li2: `	eventuale specificazione che la richiesta viene trasmessa
      anche per conto di altri (ad esempio, anche per il coniuge
      nel caso in cui abbia sostenuto parte delle spese).`
    },
    p3: `Dati Catastali`,
    ul2: {
      li1: `dati identificativi dell'immobile oggetto d'intervento;`,
      li2: `dati identificativi dell'intervento.`
    },
    p4: `Dati tecnici/finanziari dell'intervento`,
    ul3: {
      li1: `dati identificativi del tecnico abilitato eventualmente
      incaricato;`,
      li2: `dati dell'impresa che effettua i lavori.`
    }
  },
  ol5: {
    title: `Ambito di comunicazione e diffusione dei dati`,
    p1: `Il Titolare non utilizzerà i dati forniti per fini diversi
    da quelli connessi al servizio cui l'utente ha aderito, e,
    comunque, soltanto entro i limiti indicati di volta in volta
    nell'informativa.`,
    p2: `Per fini connessi all'erogazione del servizio cui l'utente
    ha aderito, i dati potrebbero essere messi a disposizione di
    soggetti terzi, che agiranno quali autonomi titolari del
    trattamento, e che erogano servizi strumentali a soddisfare
    la richiesta dell'utente o ai quali la comunicazione dei
    dati è necessaria per ottemperare a norme di legge o per lo
    svolgimento di attività strumentali alla prestazione dei
    servizi richiesti. Non saranno comunicati per altri scopi
    né, tanto meno, diffusi in alcun modo.`,
    p3: `I dati personali saranno resi disponibili a persone
    espressamente autorizzate dal Titolare - e all'uopo
    appositamente designate persone autorizzate al trattamento -
    che svolgono attività di trattamento indispensabili per il
    perseguimento delle finalità sopra indicate; potranno essere
    comunicati a dipendenti, consulenti e altri collaboratori,
    nonché a terzi fornitori di servizi che operano per conto
    del Titolare, e secondo le istruzioni ricevute, quali
    responsabili del trattamento. In linea generale, si tratta
    delle persone preposte all'erogazione dei servizi specifici,
    all'amministrazione, alla gestione dei servizi informativi.
    Si tratta di soggetti terzi che, dotati di esperienza,
    capacità tecniche, professionalità e affidabilità, svolgono
    operazioni di trattamento per conto del Titolare, nel
    rispetto della sicurezza e della riservatezza delle
    informazioni e da noi costantemente controllati nel loro
    operato.`,
    p4: `I dati saranno trattati all'interno dell'Unione Europea e
    conservati su server ubicati all'interno dell'Unione
    Europea.`,
    p5: `Gli stessi dati potranno essere trattati in Paesi al di
    fuori dell'Unione Europea, a condizione che sia garantito un
    livello di protezione adeguato, riconosciuto da apposita
    decisione di adeguatezza della Commissione Europea.`,
    p6: `Eventuali trasferimenti di Dati Personali verso Paesi non
    UE, in assenza di Decisione di adeguatezza della Commissione
    europea, saranno possibili solo qualora siano fornite dai
    Titolari e Responsabili coinvolti garanzie adeguate di
    natura contrattuale o pattizia.`,
    p7: `Il trasferimento dei dati verso Paesi terzi al di fuori
    dell'Unione Europea in mancanza di una decisione di
    adeguatezza o di altre misure adeguate come sopra descritte,
    saranno effettuati solo ove tu vi abbia esplicitamente
    acconsentito o nei casi previsti dal GDPR e saranno trattati
    nel Tuo interesse.`,
    p8: ``
  },
  ol6: {
    title: `Modalità del trattamento dei dati`,
    p1: `Nell'ambito della struttura organizzativa del Titolare, i
    dati personali saranno trattati da persone autorizzate al
    trattamento che agiscono sotto l'autorità del Titolare del
    trattamento, adeguatamente istruite dal Titolare stesso,
    principalmente con sistemi elettronici e manuali in
    conformità ai principi applicabili al trattamento di dati
    personali ai sensi dell'art. 5 del Regolamento.`,
    p2: `Tutti i trattamenti effettuati nell'ambito di questo sito o
    piattaforma saranno realizzati con strumenti elettronici o
    telematici, con logiche correlate alle finalità per le quali
    i dati sono stati raccolti e nel rispetto delle vigenti
    norme di sicurezza, per le finalità specificate di volta in
    volta nell'informativa presentata all'utente. Il Titolare
    adotta idonee, adeguate e preventive misure di sicurezza
    atte a salvaguardare la riservatezza, l'integrità, la
    completezza, la disponibilità dei Tuoi dati personali. Così
    come stabilito dai disposti normativi che disciplinano la
    sicurezza dei dati personali, sono messi a punto
    accorgimenti tecnici, logistici e organizzativi che hanno
    per obiettivo la prevenzione di danni, perdite anche
    accidentali, alterazioni, utilizzo improprio e non
    autorizzato dei dati che La riguardano. Analoghe misure
    preventive di sicurezza sono adottate dai soggetti terzi
    (responsabili del trattamento) cui il Titolare ha affidato
    operazioni di trattamento dei Tuoi dati per proprio conto.`,
    p3: `L'elenco completo ed aggiornato dei responsabili del
    trattamento può essere richiesto all'e-mail
    dpo@techvisory.it.`,
    p4: `D'altro canto, il Titolare non si ritiene responsabile circa
    le informazioni non veritiere inviate direttamente
    dall'utente (esempio: correttezza dell'indirizzo e-mail o
    recapito postale o altri dati anagrafici), così come delle
    informazioni che lo riguardano e che sono state fornite da
    un soggetto terzo, anche fraudolentemente.`
  },
  ol7: {
    title: `Periodo di conservazione dei dati`,
    p1: `I Dati Personali raccolti verranno conservati nel rispetto
    dei principi di proporzionalità e necessità, fino a che non
    siano state perseguite le finalità del trattamento
      ("principio di limitazione della conservazione", art.5,
      GDPR).`,
    p2: `	Il periodo di conservazione dei dati dipende dalle finalità
    per cui vengono trattati e pertanto potrebbe variare. I
    criteri utilizzati per determinare il periodo di
    conservazione applicabile sono i seguenti: la conservazione
    dei dati personali oggetto della presente informativa
    avverrà per il tempo necessario (i) alla gestione del
    rapporto con l'utente; (ii) a far valere diritti in sede
    giudiziaria nonché (iii) per il tempo previsto da norme di
    legge applicabili.`,
    p3: `	La verifica sulla obsolescenza dei dati conservati in
    relazione alle finalità per cui sono stati raccolti viene
    effettuata periodicamente.`
  },
  ol8: {
    title: `Diritti degli interessati`,
    p1: `In relazione al trattamento dei dati ivi descritto, potrai
    esercitare i diritti previsti dal Regolamento (artt.15-21),
    ivi inclusi:`,
    ul1: {
      li1: `ricevere conferma dell'esistenza dei Tuoi dati personali e
      accedere al loro contenuto (diritti di accesso);`,
      li2: `aggiornare, modificare e/o correggere i Tuoi dati
      personali (diritto di rettifica);`,
      li3: `chiederne la cancellazione o la limitazione del
      trattamento dei dati trattati in violazione di legge
      compresi quelli di cui non è necessaria la conservazione
      in relazione agli scopi per i quali i dati sono stati
      raccolti o altrimenti trattati (diritto all'oblio e
      diritto alla limitazione);`,
      li4: `opporti al trattamento (diritto di opposizione);`,
      li5: `revocare il consenso, ove prestato, senza pregiudizio per
      la liceità del trattamento basata sul consenso prestato
      prima della revoca;`,
      li6: `proporre reclamo all'Autorità di controllo in caso di
      violazione della disciplina in materia di protezione dei
      dati personali;`,
      li7: `ricevere copia dei dati in formato elettronico che ti
      riguardano e chiedere che tali dati siano trasmessi ad un
      altro titolare del trattamento (diritto alla portabilità
      dei dati).`
    },
    p2: `Per l'esercizio dei Tuoi diritti e per la revoca del Tuo
    consenso potrai rivolgerti al Responsabile della protezione
    dei dati personali inviando una email a dpo@techvisory.it.`,
    p3: `Ti ricordiamo che è un Tuo diritto proporre un reclamo al
    Garante per la Protezione dei Dati Personali, mediante:
    raccomandata A/R indirizzata a Garante per la protezione dei
    dati personali, Piazza Venezia, 11, 00187 Roma; e-mail
    all'indirizzo: protocollo@gpdp.it, oppure
    protocollo@pec.gpdp.it (solo per comunicazioni provenienti da posta elettronica
    certificata); fax al numero: 06/69677.3785.`
  }
}
