import React from "react";
import { Row, Col } from "react-bootstrap";
import { useRoute } from "../../../library/utils/routes/useRoute";

const Footer = () => {
  const { changeRoute } = useRoute();

  return (
    <footer className="d-flex justify-content-center">
      <Row className="justify-content-center align-items-center footer">
        <Col className="text-center">
          <a className="privacy-policy">
            <span onClick={() => changeRoute("privacyPolicy")}>
              Privacy Policy
            </span>
          </a>{" "}
          |{" "}
          <a className="cookie-policy">
            <span onClick={() => changeRoute("cookiePolicy")}>
              Cookie Policy
            </span>
          </a>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
