import { Routes } from "../components/routes/models/routes";

const routes: Routes = {
  list: [
    {
      path: "/",
      component: "features/landing/Landing",
      name: "landing",
    },
    {
      path: "/privacy-policy",
      component: "features/privacyPolicy/PrivacyPolicy",
      name: "privacyPolicy",
    },
    {
      path: "/cookie-policy",
      component: "features/cookiePolicy/CookiePolicy",
      name: "cookiePolicy",
    },
    {
      path: "/signInDelegate",
      component: "features/signIn/SignInDelegate",
      name: "signInDelegate",
    },
    {
      path: "/signInCompany",
      component: "features/signIn/SignInCompany",
      name: "signInCompany",
    },
  ],
  redirect: "/",
};
export default routes;
