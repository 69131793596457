import React, { useEffect, useState } from "react";
import axios from "axios";
import { WellKnown } from "./models/WellKnown";

function useWellKnown(): WellKnown {
  const [wellKnow, setWellKnown] = useState((null as unknown) as WellKnown);

  const origin = `${window.location.protocol}//${window.location.hostname}${
    window.location.port !== "" ? `:${window.location.port}` : ""
  }`;

  function getWellKnownUrl(default_api: boolean = false): string {
    let result = "";
    if (!default_api) {
      const { hostname, protocol } = window.location;
      const hostnameSplited = hostname.split(".");
      const api_subdomain =
        hostnameSplited[0] === "market" ? "api-market" : "api";
      let [a, b, ..._] = hostnameSplited.reverse();
      result = `${protocol}//${api_subdomain}.${
        b ? b + "." : ""
      }${a}/.well-known/app-configuration?origin=${origin}/`;
    } else if (!origin.includes("localhost")) {
      // default per produzione
      result = `https://api.flyecobonus.it/.well-known/app-configuration?origin=${origin}`;
    } else {
      throw new Error("Wellknown not found");
    }
    console.debug(result);
    return result;
  }

  function createWellKnown(
    data: any,
    origin: string,
    setWellKnown: React.Dispatch<React.SetStateAction<WellKnown>>
  ) {
    console.log(data);
    data.metadata.issuer.redirect_uri = origin;
    console.debug(`WELL KNOWN: ${data}`);
    setWellKnown(data);
    WellKnownProfile.setData(data);
  }

  useEffect(() => {
    if (!WellKnownProfile.getData()) {
      const wellKnownEnv =
        process.env.REACT_APP_WELL_KNOWN ||
        localStorage.getItem("__well_known_api__");
      WellKnownProfile.setUrl(wellKnownEnv || getWellKnownUrl());
      axios
        .create()
        .get(WellKnownProfile.getUrl())
        .then(({ data }) => {
          createWellKnown(data, origin, setWellKnown);
        })
        .catch((err) => {
          WellKnownProfile.setUrl(getWellKnownUrl(true));
          axios
            .create()
            .get(WellKnownProfile.getUrl())
            .then(({ data }) => {
              createWellKnown(data, origin, setWellKnown);
            });
        });
    } else {
      setWellKnown(WellKnownProfile.getData());
    }
  }, []);

  return wellKnow;
}

export const WellKnownProfile = (function () {
  let data: WellKnown;

  let url: string;

  const getData = function () {
    return data;
  };

  const getEnpointApi = function () {
    return data?.metadata?.endpoint_api;
  };

  const getActiveDebug = function () {
    return data?.metadata?.active_debug;
  };

  const setData = function (input: WellKnown) {
    data = input;
  };

  const getUrl = function () {
    return url;
  };

  const setUrl = function (input: string) {
    url = input;
  };

  return {
    getData,
    setData,
    getEnpointApi,
    getActiveDebug,
    getUrl,
    setUrl,
  };
})();

export default useWellKnown;
