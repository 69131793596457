export default {
  fe: {
    required: "Il campo {{name}} è obbligatorio",
    email: "Il campo {{name}} non contiene un'e-mail valida",
    confirm: "Il campo {{fieldCompare}} deve essere uguale al campo {{name}}",
    requiredOne: "Compilare il campo {{name}} o il campo {{moreData.name}}",
    cf: "Il campo {{name}} non è corretto",
    piva: "Il campo {{name}} non è corretto",
    cfORpivaNotRequired:
      "Il campo {{name}} deve essere un codice fiscale o p.iva valido",
    cap: "Il campo {{name}} non è corretto",
    isNumberLessThan: "Il campo {{name}} deve essere inferiore a {{max}}",
    isNumberGreaterThan: "Il campo {{name}} deve essere superiore a {{min}}",
    minLength: "Il campo {{name}} deve essere almeno di {{min}} caratteri",
    maxLength: "Il campo {{name}} non può essere maggiore a {{max}} caratteri",
    minDate: "Il campo {{name}} non può essere antecedente al {{value}}",
    maxDate: "Il campo {{name}} non può essere successivo al {{value}}",
    percentage: "Il campo {{name}} non contiene una percentuale",
    isNumber: "Il campo {{name}} non contiene un numero",
    url: "Il campo {{name}} non contiene un url valido",
    upperCharacter: "Il campo {{name}} deve avere almeno una lettera maiuscola",
    oneNumber: "Il campo {{name}} deve contenere almeno un numero",
    specialCharacter:
      "Il campo {{name}} deve contenere almeno un carattere speciale tra @#!~$%^&*()-+/:.,<>?|",
    noPermission: 'Non hai i permessi'
  },
};
