export default {
  userDropdown: {
    manageProfile: "Accountverwaltung",
    manageConsents: "Gestione consenso (DE)",
    logout: "Log out",
  },
  privacyConsent: {
    description:
     " Nach Kenntnisnahme dieser Informationen stimme ich der Verarbeitung meiner Daten zu folgenden Zwecken zu:",
  },
  signIn: "Registrierung",
  signInDelegate: "Operator registrieren",
  signInCompany: "Endbegünstigte registrieren",
  signup: "Melde dich an",
  dataCompany: "Studiendaten",
  tabs: {
    profile: "Profil",
    professional: "Berufliche Daten",
    birth: "Geburtsdatum",
    address: "Adresse",
    skills: "Kompetenzen",
  },
};
