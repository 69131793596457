export default {
  title: 'Datenschutzrichtlinie',
  subTitle: `Datenschutzinformation gemäß Artikel13 der Verordnung (EU) Nr. 679/2016`,
  p1: `Die geltende Datenschutzregelung, die in Übereinstimmung mit den Bestimmungen der Verordnung (EU) 2016/679 vom 27. April 2016 zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten und zum freien Datenverkehr (Allgemeine Datenschutzverordnung, nachstehend "EU-Verordnung" oder "DSGVO") und in Übereinstimmung mit dem GvD Nr. 196/2003 festgelegt wurde, das durch das GvD Nr. 101/2018 geändert wurde, enthält Bestimmungen, die sicherstellen sollen, dass die Verarbeitung personenbezogener Daten unter Einhaltung der Grundrechte und -freiheiten natürlicher Personen, insbesondere was das Recht auf den Schutz personenbezogener Daten angelangt, erfolgt.`,
  p2: `Die vorliegende Datenschutzinformation, die gemäß Artikel 13 der Verordnung (EU) 2016/679 (nachstehend Verordnung) erstellt wurde, ist für die Benutzer bestimmt, die sich auf der Website globalbonus.flyecobonus.it für die Abwicklung des Bonusverfahrens für die energetische Sanierung von bestehenden Gebäuden registrieren.`,
  ol1: {
    title: `Der Datenverantwortliche`,
    p1: `Datenverantwortlicher ist die Gesellschaft TechVisory srl mit Sitz in Trient (Via Kufstein 5, 38121 Trento).`
  },
  ol2: {
    title: `Der Datenschutzbeauftragte`,
    p1: `Um Ihnen die Kontaktaufnahme zu erleichtern, hat der Verantwortliche einen eigenen Datenschutzbeauftragten ("DSB") ernannt, den Sie unter der E-Mail-Adresse dpo@techvisory.it für alle Fragen in Zusammenhang mit der Verarbeitung Ihrer personenbezogenen Daten und der Ausübung Ihrer Rechte kontaktieren können, die Sie gemäß der DSGVO und nach den Modalitäten geltend machen können, wie sie im Abschnitt "Rechte der betroffenen Person" dieser Datenschutzinformation beschrieben werden.`
  },
  ol3: {
    title: `Zweck der Datenverarbeitung`,
    p1: `Die Sie betreffenden personenbezogenen Daten werden - abgesehen von den Angaben zu den Navigationsdaten - gemäß den Vorgaben der oben genannten Rechtsvorschriften in Übereinstimmung mit den in Artikel 5, Absatz 1 der DSGVO vorgesehenen Grundsätzen der Rechtmäßigkeit, Redlichkeit, Transparenz, Angemessenheit, Relevanz und Notwendigkeit verwaltet und im Rahmen der Abwicklung des Bonusverfahrens für energetische Sanierung verarbeitet. `,
    p2: `Die Bereitstellung der Daten für die oben genannten Zwecke ist obligatorisch. Sollten diese Daten nicht angegeben werden oder diese Datenverarbeitung ausdrücklich abgelehnt werden, kann der Datenverantwortliche seine Leistungen nicht erbringen. `,
    p3: `Ihre persönlichen Daten können bei Bedarf folgenden Personen/Stellen offengelegt werden:`,
    ul1: {
      li1: `den Rechtssubjekten, denen die Daten zur Erfüllung einer gesetzlichen, verordnungsrechtlichen oder EU-rechtlichen Verpflichtung oder zur Erfüllung einer gerichtlichen Anordnung gemeldet werden müssen;`,
      li2: `den Personen, die vom Datenverantwortlichen als Auftragsverarbeiter benannt wurden, bzw. den Personen, die zur Verarbeitung personenbezogener Daten berechtigt sind und ihre Tätigkeit direkt unter der Aufsicht des Datenverantwortlichen oder des Auftragsverarbeiters ausführen;`,
      li3: `eine vollständige und aktualisierte Liste der vom Datenverantwortlichen bestimmten Auftragsverarbeiter kann beim Verantwortlichen unter der angegebenen Adresse angefordert werden;`,
      li4: `etwaigen anderen Drittstellen, wie öffentlichen Einrichtungen oder institutionellen Behörden, und zwar in den gesetzlich ausdrücklich vorgesehenen Fällen, oder wenn die Meldung zur Vertretung der Gesellschaft TechVisory srl vor Gericht notwendig ist, wobei diese in Übereinstimmung mit den geltenden Datenschutz-Bestimmungen erfolgt. `
    },
    p4: `Mit Ihrer ausdrücklichen und freien Zustimmung können Ihre Daten für weitere Zwecke verarbeitet werden:`,
    ol1: {
      li1: `für das Direktmarketing, d.h. für die Zusendung von Werbe-/Informationsmaterial, kommerziellen Mitteilungen, Werbeinitiativen, für die Durchführung von Marktforschungen oder Studien zur Kundenzufriedenheit durch den Datenverantwortlichen, und zwar mit interaktiven und "traditionellen“ Methoden (z.B. Papierpost und/oder Anruf mit Operator) oder durch "automatisierte" Kontaktsysteme (z.B. SMS und/oder MMS, automatische Anrufsysteme ohne menschlichen Eingriff, E-Mail, Fax, interaktive Anwendungen). Die betroffene Person wird jedoch darauf hingewiesen, dass - wie von der Datenschutz-Aufsichtsbehörde vorgesehen - aufgrund der spezifischen Vorschrift der Aufsichtsbehörde Nr. 330 vom 4. Juli 2013 (sog. Soft-Spam) die im Rahmen der vertraglichen Beziehungen mit den eigenständigen Datenverantwortlichen bereitgestellte E-Mail-Adresse, die über dieses Portal erreichbar sind, von diesen Verantwortlichen für die Übermittlung von kommerziellen Angeboten für ähnliche Produkte und/oder Dienstleistungen verwendet werden kann, wie sie zuvor erworben wurden. Die betroffene Person kann jederzeit ihr Widerspruchsrecht ausüben und sich der Verarbeitung widersetzen;`,
      li2: `für die Analyse der Neigung zur Nutzung der vom Unternehmen angebotenen Produkte und Dienstleistungen, für die Erstellung von Einzel- und Gruppenprofilen, für die Unterbreitung von individuellen Angeboten, die auch durch den Einsatz von Tools und Anwendungen zur Erkennung von Verbraucherpräferenzen ausgearbeitet werden, und für die - auch in personalisierter, automatisierter oder elektronischer Form durchgeführte - Analyse der im Rahmen der Nutzung von Produkten und Dienstleistungen erhobenen Informationen, allerdings nur mit Ihrer Einwilligung oder wenn dies für den Vertragsabschluss oder die Vertragsausführung nötig ist, können Profilierungstechniken zur Anwendung gelangen; `,
      li3: `für die Offenlegung von Daten an Dritte zu Marketingzwecken, bezogen auf Bankinstitute, Finanzvermittler und Versicherungsgesellschaften.`
    },
    p5: `Die Rechtsgrundlage für die Durchführung der Verarbeitung und die Erreichung der oben genannten Zwecke liegt in Ihrer Einwilligung, die frei, spezifisch, bewusst und eindeutig erfolgen muss. Die Bereitstellung der Daten zur Erreichung der oben genannten Zwecke ist freiwillig, eine etwaige Verweigerung hat keine Folgen für die Ausführung des Vertragsverhältnisses. Die von Ihnen für diese Zwecke erteilte Einwilligung kann jederzeit widerrufen werden.`
  },
  ol4: {
    title: `Art der verarbeiteten Daten`,
    p1: `Das Unternehmen TechVisory srl sammelt als Datenverantwortlicher folgende Kategorien von Daten:`,
    p2: `Persönliche Daten`,
    ul1: {
      li1: `Persönliche Daten des Begünstigten oder des Vermittlers, die während der Registrierung auf der Website (oder Plattform) globalbonus.flyecobonus.it und in der unmittelbar folgenden Phase gesammelt werden, einschließlich der Kontaktdaten (d.h. Telefonnummer und E-Mail-Adresse);`,
      li2: `eventuell der Hinweis, dass die Anfrage auch für andere übermittelt wird (z.B. auch für den Ehepartner, wenn dieser einen Teil der Kosten getragen hat).`
    },
    p3: `Katasterdaten`,
    ul2: {
      li1: `Identifikationsdaten des Gebäudes, an dem die baulichen Maßnahmen durchgeführt werden sollen;`,
      li2: `Identifikationsdaten der baulichen Maßnahme.`
    },
    p4: `Technische/finanzielle Daten der baulichen Maßnahme`,
    ul3: {
      li1: `Identifikationsdaten des eventuell beauftragten Technikers mit der entsprechenden Befähigung;`,
      li2: `Daten des Unternehmens, das die Bauarbeiten ausführt.`
    }
  },
  ol5: {
    title: `Offenlegungsbereich und Weitergabe von Daten`,
    p1: `Der Datenverantwortliche wird die bereitgestellten Daten nicht für andere Zwecke als für jene des Dienstes, bei dem sich der Benutzer registriert hat, und auf jeden Fall nur innerhalb der zulässigen Grenzen nutzen, die jeweils in der Datenschutzinformation angegeben sind.`,
    p2: `Für Zwecke im Rahmen der Erbringung des vom Benutzer beantragten Dienstes könnten die Daten Dritten zur Verfügung gestellt werden, die als eigenständige Datenverantwortliche fungieren und Leistungen erbringen, die der Erfüllung der Anfrage des Benutzers dienen, oder denen die Daten offengelegt werden müssen, um gesetzlichen Anforderungen nachzukommen oder Tätigkeiten auszuführen, die der Bereitstellung der angeforderten Dienstleistungen dienen. Die Daten werden nicht für andere Zwecke weitergegeben und werden in keiner Weise verbreitet.`,
    p3: `Die personenbezogenen Daten werden den vom Verantwortlichen eigens ermächtigten Personen zugänglich gemacht, die zu diesem Zweck ausdrücklich als zur Datenverarbeitung Berechtigte bezeichnet werden und Datenverarbeitungsvorgänge durchführen, die unbedingt nötig sind zur Erreichung der oben genannten Zwecke. `,
    p4: `Die Daten können Angestellten, Beratern und anderen Mitarbeitern sowie außenstehenden Dienstleistern offengelegt werden, die im Auftrag des Datenverantwortlichen und nach seinen Anweisungen als Auftragsverarbeiter tätig sind. Im Allgemeinen handelt es sich um die Personen, die für die Bereitstellung spezifischer Dienste, für die Verwaltung und das Management von IT-Diensten zuständig sind. Dabei handelt es sich um Drittdienstleister, die mit Erfahrung, technischer Kompetenz, Professionalität und Zuverlässigkeit im Auftrag des Datenverantwortlichen Datenverarbeitungsvorgänge unter Wahrung der Sicherheit und Vertraulichkeit der Daten durchführen und deren Arbeit von uns ständig überwacht wird. `,
    p5: `Die Daten werden innerhalb der Europäischen Union verarbeitet und auf Servern gespeichert, die sich in der Europäischen Union befinden.`,
    p6: `Dieselben Daten können in Ländern außerhalb der Europäischen Union verarbeitet werden, soweit ein angemessenes Datenschutzniveau gewährleistet wird, das durch einen eigenen Angemessenheitsbeschluss der Europäischen Kommission anerkannt sein muss.`,
    p7: `Eine etwaige Übermittlung personenbezogener Daten in Nicht-EU-Länder ist ohne einen Angemessenheitsbeschluss der Europäischen Kommission nur dann möglich, wenn von den beteiligten Datenverantwortlichen und Auftragsverarbeitern angemessene vertragliche Garantien vorgelegt werden.`,
    p8: `Die Übermittlung von Daten in Drittländer außerhalb der Europäischen Union ohne Angemessenheitsbeschluss oder ohne die anderen oben beschriebenen geeigneten Maßnahmen erfolgt nur, wenn Sie ausdrücklich zugestimmt haben, oder in den in der DSGVO vorgesehenen Fällen; die Datenverarbeitung erfolgt auf jeden Fall in Ihrem Interesse.`
  },
  ol6: {
    title: `Modalitäten der Datenverarbeitung`,
    p1: `Innerhalb der Organisationsstruktur des Datenverantwortlichen werden personenbezogene Daten von Personen mit entsprechender Berechtigung verarbeitet; diese handeln unter Aufsicht des Datenverantwortlichen selbst und erhalten von ihm eine angemessene Einweisung. Die Verarbeitung erfolgt hauptsächlich mit elektronischen und manuellen Systemen in Übereinstimmung mit den Grundsätzen, die gemäß Artikel 5 der Verordnung für die Verarbeitung personenbezogener Daten gelten. `,
    p2: `Alle im Rahmen dieser Website oder Plattform vorgesehenen Verarbeitungsvorgänge werden mit elektronischen oder telematischen Instrumenten mit einer Logik, die eng mit den Zwecken verbunden ist, für die die Daten gesammelt werden, und unter Einhaltung der geltenden Sicherheitsbestimmungen für die Zwecke durchgeführt, die jeweils in der dem Benutzer vorgelegten Datenschutzinformation genannt werden. Der Datenverantwortliche ergreift geeignete, angemessene und vorbeugende Sicherheitsmaßnahmen, um die Vertraulichkeit, Integrität, Vollständigkeit und Verfügbarkeit Ihrer personenbezogenen Daten zu gewährleisten. Entsprechend den Vorschriften über die Sicherheit personenbezogener Daten werden technische, logistische und organisatorische Maßnahmen ergriffen, um eine Beschädigung, einen – auch zufälligen - Verlust, eine Änderung, eine missbräuchliche und unberechtigte Verwendung Ihrer Daten zu verhindern. Ähnliche vorbeugende Sicherheitsmaßnahmen werden von Drittdienstleistern (Auftragsverarbeiter) ergriffen, denen der Datenverantwortliche die Verarbeitung Ihrer Daten in seinem eigenen Namen übertragen hat.`,
    p3: `Eine vollständige und aktuelle Liste der Auftragsverarbeiter kann per E-Mail unter der Adresse dpo@techvisory.it angefordert werden.`,
    p4: `Andererseits ist der Datenverantwortliche weder für unrichtige Informationen, die direkt vom Benutzer gesendet werden (z.B.: Richtigkeit der E-Mail-Adresse oder der Postanschrift oder anderer persönlicher Daten), noch für Angaben zum Benutzer verantwortlich, die von einer dritten Person, auch in betrügerischer Absicht, zur Verfügung gestellt werden.`
  },
  ol7: {
    title: `Dauer der Datenspeicherung`,
    p1: `Die gesammelten personenbezogenen Daten werden gemäß den Grundsätzen der Verhältnismäßigkeit und der Erforderlichkeit gespeichert, bis die Zwecke der Verarbeitung erfüllt sind ("Grundsatz der Speicherbegrenzung", Art. 5, DSGVO).`,
    p2: `Die Speicherdauer hängt von den Datenverarbeitungszwecken ab und kann daher variieren. Die anzuwendende Speicherdauer wird anhand folgender Kriterien bestimmt: Die Aufbewahrung von personenbezogenen Daten, die Gegenstand dieser Datenschutzinformation sind, erfolgt für den Zeitraum, der erforderlich ist, (i) um die Benutzerbeziehung zu pflegen; (ii) um Rechtsansprüche gerichtlich durchzusetzen sowie (iii) für den Zeitraum, der gemäß den einschlägigen Gesetzesbestimmungen vorgeschrieben ist.`,
    p3: `Die Überprüfung der Überalterung der gespeicherten Daten in Bezug auf die Zwecke, für die Sie erhoben wurden, wird periodisch durchgeführt.`
  },
  ol8: {
    title: `Rechte der betroffenen Personen`,
    p1: `In Bezug auf die hier beschriebene Datenverarbeitung können Sie die folgenden in der Verordnung (Artikel 15-21) vorgesehenen Rechte ausüben. Dazu gehören:`,
    ul1: {
      li1: `das Recht, eine Bestätigung darüber zu erhalten, ob Sie betreffende personenbezogene Daten verarbeitet werden, und das Recht auf Auskunft über deren Inhalt (Auskunftsrecht);`,
      li2: `das Recht, Ihre personenbezogenen Daten zu aktualisieren, zu ändern und/oder zu berichtigen (Recht auf Berichtigung);`,
      li3: `das Recht, die Löschung oder Einschränkung von rechtswidrig verarbeiteten Daten zu verlangen, auch von Daten, deren Speicherung nicht erforderlich ist für die Zwecke, für die diese Daten erhoben oder sonst wie verarbeitet wurden (Recht auf Vergessenwerden und Recht auf Einschränkung);`,
      li4: `das Recht, gegen die Verarbeitung Widerspruch einzulegen (Widerspruchsrecht);`,
      li5: `das Recht, eine gegebenenfalls erteilte Einwilligung zu widerrufen, ohne dass die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird;`,
      li6: `das Recht, bei Verletzung der Datenschutzbestimmungen eine Beschwerde bei der Aufsichtsbehörde einzureichen;`,
      li7: `das Recht, eine Kopie der Sie betreffenden Daten in elektronischem Format zu erhalten, und das Recht, die Übertragung dieser Daten an einen anderen Verantwortlichen zu verlangen (Recht auf Datenübertragbarkeit).`
    },
    p2: `Um Ihre Rechte auszuüben und Ihre Einwilligung zu widerrufen, können Sie sich an den Datenschutzbeauftragten wenden und ihm dazu eine E-Mail an dpo@techvisory.it senden.`,
    p3: `Bitte beachten Sie, dass Sie das Recht haben, eine Beschwerde an die Datenschutz-Aufsichtsbehörde zu richten: per Einschreiben mit Rückantwort an die Behörde (Garante per la protezione dei dati personali, Piazza Venezia, 11, 00187 Rom); per E-Mail an: protocollo@gpdp.it oder protocollo@pec.gpdp.it (nur für Mitteilungen, die von zertifizierten E-Mail-Adressen aus gesendet werden); per Fax an: 06/69677.3785.`
  }
}
