export default {
  general: {
    finishedWorks: 'Abgeschlossene Arbeiten',
    startedWorks: 'Begonnene Arbeiten',
    completed: 'Abgeschlossen',
    draft: 'Entwurf',
    status: 'Aufwandstatus',
    practiceID: 'AufwandID',
    startDate: 'Beginn der Arbeiten',
    endDate: 'Abschluss von Arbeiten',
    offers: 'Angebote',
    offersAccepted: "Angebote angenommen",
    enterpriseAdd: 'Bauunternehmen eingeben',
    enterpriseChoice: 'Bauunternehmen auswählen',
    operatorAdd: 'Wirtschaftsteilnehmer eingeben',
    operatorChoice: 'Wirtschaftsteilnehmer auswählen',
    certFiscalChoice: 'Steuerzertifizierer auswählen',
    certFiscalAdd: 'Steuerzertifizierer eingeben',
    certTechChoice: 'Technischen Zertifizierer auswählen',
    certTechAdd: 'Technischen Zertifizierer eingeben',
    interventiTrainati: 'Begünstigte Nebenarbeiten',
    interventiTrainanti: 'Begünstigte Hauptmaßnahmen',
    interventi: 'MAßnahmen',
  },
  steps: {
    step2: 'MAßnahmen und Stand',
    step7: 'Anmerkungen',
    step8: 'Dokumenten',
  },
  create: {
    newPractice: 'Neue Aufwand',
    generalLabel: {
      fiscalCode: 'Steuernummer',
      pIva: 'MwSt.-nr',
      profession: 'Beruf',
      name: 'Name',
      surname: 'Nachname',
      gender: 'Geschlecht',
      email: 'E-mail',
      pec: 'PEC',
      telephone: 'Telefon',
      birthdatas: 'Geburtsdaten',
      birthdata: 'Geburtsdatum',
      state: 'Nation',
      province: 'Provinz',
      municipality: 'Gemeinde',
      locality: 'Localität',
      address: 'Adresse',
      street: 'Weg',
      cap: 'CAP',
      private: 'Privat',
      condominium: 'Eigentumswohnung',
      legalPerson: 'Juridische Person',
      businessName: 'Firmenname',
      legalSpecies: 'Juridische Art',
      subjectTipology: 'Subjektart',
      role: 'Rolle',
      competencies: 'Kompetenzen',
      cv: 'Curriculum',
      reference: 'Referenzen',
      intervento: 'Intervention',
      category: 'Kategorie',
      propertyType: 'Immobilart',
      cancelConfirmDialog: {
        title: 'Sind Sie sicher, die Aufwand aufzugeben?',
        subtitle: 'Die Unterlagen finden Sie in den Entwürfen',
      },
    },
    customer: {
      customerDatas: 'Kundendaten',
      denomination: 'Bezeichnung',
      asDelegate: 'Gleich an der Delegierten',
      confirmOverwritePrivate: {
        title: 'Anfrage bestätigen',
        subtitle:
          'Sind Sie sicher, die Daten des Bevollmächtigten mit dem Delegierten zu überschreiben?',
      },
      enableDelegateError: 'Fehler bei der Aktivierung des Delegierten',
      enableDelegateSuccess: 'Delegierter erfolgreich aktiviert',
      newDelegateSuccess: 'Delegierter erfolgreich erstellt',
      updateDelegateSuccess: 'Delegierter erfolgreich aktualisiert',
      overwriteCustomerDialogConfirm: {
        title: 'Daten des Delegierten überschreiben?',
        body:
          'Daten des Delegierten von dem ausgewählten Bevollmächtigten hochladen?',
      },
      asEmissary: 'Identisch mit dem Begünstigten',
    },
    operation: {
      subtitle: 'Wählen Sie die Intervention',
      statusPractice: 'Stand der Unterlagen',
      expectedDateEndWork: 'Vorgesehene Abschlussdatum der Arbeiten',
      dateStartWork: 'Beginnsdatum der Arbeiten',
      dateEndWork: 'Abschlussdatum der Arbeiten',
      percentageProgressWork: 'Prozentualer Anteil am Arbeitsfortschritt',
      totalTaxCredit: 'Gesamt Steuergutschrift',
      discountInvoice: 'Rabatt auf der Rechnung',
      amountDiscountInvoice: 'Abgezinster Betrag auf der Rechnung',
      creditTransfer: 'Steuergutschriftabtretung',
      choose: 'Wählen',
      agreed: 'Gewährt',
      requested: 'Erforderlich',
      filter: 'Filter',
      amountSpent: 'Ausgabenbetrag',
      aeInstructions: 'Anweisungen Formular Einnahmenagentur',
      no_operation_selected: 'Keine Intervention ausgewählt',
      changeDeductionDialog: {
        title: 'Achtung!',
        subtitle:
          'Wenn man die Steuerabsetzung ändert, gehen alle Informationen zu den Baumaßnahmen, die Informationen des Steuerzertifizierers, des technischen Zertifizierers und der bereits eingegebenen Dokumente verloren',
      },
      deductionTypes: {
        SUPERBONUS_110: 'SUPERBONUS 110%',
        BONUS_FACCIATE: ' FASSADEN-BONUS',
        SISMABONUS: 'ERDBEBEN-BONUS',
        INF_RIC_VEICOLI_ELETTRICI: 'LADEINFRASTRUKTUR E-FAHRZEUGE',
        RECUPERO_PATRIMONIO_EDILIZIO: 'WIEDERGEWINNUNG VON GEBÄUDEN ',
        ECOBONUS: 'ÖKOBONUS',
        BONUS_VERDE: 'INSTALLATION PHOTOVOLTAIKANLAGEN',
        ECO_SISMABONUS: 'ÖKO-ERDBEBEN-BONUS',
        SISMABONUS_110: 'ERDBEBEN-BONUS 110%',
      },
      operationTypes: {
        'tipo_-1': 'Eliminazione barriere architettoniche',
        tipo_1:
          "Intervento di isolamento termico delle superfici opache verticali, orizzontali o inclinate che interessano l'involucro dell'edificio con un'incidenza superiore al 25%",
        tipo_2:
          'Intervento per la sostituzione degli impianti di climatizzazione invernale esistenti',
        tipo_3: 'Riqualificazione energetica di edificio esistente',
        tipo_4: 'Coibentazione involucro',
        tipo_5: 'Acquisto e posa in opera di finestre comprensive di infissi',
        tipo_6:
          'Sostituzione impianti di climatizzazione invernale con caldaie a condensazione Classe A',
        tipo_7:
          'Intervento di sostituzione di impianti di climatizzazione invernale con caldaie a condensazione classe A+ sistemi di termoregolazione o con generatori ibridi o con pompe di calore; intervento di sostituzione di scaldacqua',
        tipo_8: 'Installazione di pannelli solari su edificio esistente',
        tipo_9: 'Acquisto e posa in opera di schermature solari',
        tipo_10:
          'Sostituzione impianti di climatizzazione invernale con generatori di calore alimentati a biomasse combustibili',
        tipo_11:
          'Acquisto e posa in opera di micro-cogeneratori in sostituzione di impianti esistenti',
        tipo_12:
          'Acquisto e installazione dispositivi multimediali per il controllo da remoto',
        tipo_13: 'Intervento antisismico in zona sismica 1,2 e 3',
        tipo_14:
          'Intervento antisismico in zona sismica 1,2 e 3 con passaggio a 1 classe inferiore',
        tipo_15:
          'Intervento antisismico in zona sismica 1,2 e 3 con passaggio a 2 classi inferiori',
        tipo_16:
          'Intervento ai fini del risparmio energetico basato su fonti di energia rinnovabili',
        tipo_17:
          'Intervento di manutenzione straordinaria, restauro e recupero conservativo o ristrutturazione edilizia e intervento di manutenzione ordinaria effettuato sulle parti comuni di un edificio',
        tipo_18:
          'Intervento di recupero o restauro della facciata di edifici esistenti',
        tipo_19:
          "Intervento per l'installazione di impianti solari fotovoltaici connessi alla rete elettrica su edifici",
        tipo_20:
          "Intervento per l'installazione contestuale o successiva di sistemi di accumulo integrati negli impianti solari fotovoltaici agevolati",
        tipo_21:
          "Intervento per l'installazione di colonnine per la ricarica di veicoli elettrici negli edifici",
        tipo_22:
          'CONDOMINI: Coibentazione involucro con superficie interessata superiore al 25% della superfice disperdente',
        tipo_23:
          "CONDOMINI: Coibentazione involucro con superficie interessata superiore al 25% della superfice disperdente e conseguimento della qualità media dell'involucro",
        tipo_24:
          'CONDOMINI: coibentazione involucro con superficie interessata superiore al 25% della superfice disperdente e riduzione di 1 classe del rischio sismico',
        tipo_25:
          'CONDOMINI: coibentazione involucro con superficie interessata superiore al 25% della superfice disperdente e riduzione di 2 classi del rischio sismico',
        tipo_26:
          "Acquisto di un'unità immobiliare antisismica per passaggio a 1 classe inferiore",
        tipo_27:
          "Acquisto di un'unità immobiliare antisismica e interventi per passaggio a 2 classi inferiori",
      },
    },
    property: {
      propertyTitle: 'Immobildaten',
      singleFamily: 'Einfamilienhaus',
      plurifamily: 'Selbstständig im Mehrfamilienhaus',
      condominium: 'Gemeinsame Teile in Eigentumswohnung',
      singoloincondominio:
        'Einzelne Immobilieneinheiten in einem Mehrparteiengebäude',
      edificiVincolati: 'Gebäude mit Bindung',
      estateUnits: 'Gebäudeteile',
      categoryCatastal: 'Katasterkategorie',
      sheet: 'Blatt',
      particle: 'Parzelle',
      subordinate: 'Unterstellten',
      buildingTypes: {
        A_1: 'A/1 - Herrschaftliche Wohnung',
        A_2: 'A/2 - Bürgerliche Wohnungen ',
        A_3: 'A/3 - Ökonomische Wohnungen ',
        A_4: 'A/4 - Volkswohnungen',
        A_5: 'A/5 - Einfache Volkswohnungen ',
        A_6: 'A/6 - Bäuerliche Wohnungen ',
        A_7: 'A/7 - Villenwohnungen',
        A_8: 'A/8 - Villa',
        A_9: 'A/9 - Schlösser, kunsthistorisch wertvolle Gebäude',
        A_10: 'A/10 - Büros',
        A_11: 'A/11 - Hütte, Almhütte, Schutzhütte',
      },
    },
    enterprise: {
      activity: 'Aktivitat',
      numberSigningCCIAA: 'Nr. CCIAA Anmeldung',
      of: 'von',
      referentData: 'Kontaktstelledaten',
      title_1: 'Arbeitsunternehmen',
      title_2: 'Arbeitsstand',
      chooseActivity:
        'Art der Intervention auswählen und Eingabe des Arbeitsunternehmens vornehmen',
    },
    certTechnical: {
      isRegisteredAlbo: 'Eingetragen im Register',
      registrationAlbo: 'Eintragung ins Register',
      college: 'Zur Auftrag/Berufsschule',
      of: 'von',
      numberRegistration: 'Anmeldungsnummer',
      certificate: 'Zertifikat',
      insurance: 'Professionelle technische Haftpflichtversicherung',
      IsInsurance: 'Versichert',
      numbererInsurance: 'Versicherungsnummer',
      companyInsurance: 'Versicherungsunternehmen',
      authorisedBy: 'Authorisiert von',
      amountInsurance: 'Versicherter Betrag',
      title: 'Technischer Zertifizierer',
      asEmissary: 'Identisch mit dem Begünstigten',
      select: 'Wirtschaftstreibenden auswählen',
    },
    certFiscal: {
      isRegisteredAlbo: 'Eingetragen im Register',
      registrationAlbo: 'Eintragung ins Register',
      college: 'Zur Auftrag/Berufsschule',
      of: 'von',
      numberRegistration: 'Anmeldungsnummer',
      certificate: 'Zertifikat',
      title: 'Steuerzertifizierer',
      select: 'Wirtschaftstreibenden auswählen',
    },
    note: {
      info: 'Weiteren informationen',
      placeholder: 'Gegebenenfalls Anmerkungen einfügen',
      enterpriseNotes: 'Anmerkungen Bauunternehmen:',
      fiscalNotes: 'Anmerkungen Steuerzertifizierer',
      techNotes: 'Anmerkungen technischer Zertifizierer ',
    },
    documents: {
      documentList: 'Dokumentation',
    },
    praticaAE: {
      subtitle: 'Muster für die Übermittlung an die Agenzie delle Entrate',
      firma: 'Daten bestätigen',
      superbonusonly: 'Nur bei Superbonus auszufüllen',
      submitCompleted:
        'Erfolgreiches Speichern von Unterlagen des Superbonus; Datei herunterladen und in die Telematik-Desktop der Agenzia delle Entrate importieren',
      buttons: {
        model: 'Vordruck',
        transmission: 'Sendung',
      },
      dati_preliminari: {
        title: 'Gültigen Begünstigter wählen',
        tipo_soggetto: 'Begünstigtersart',
        tipo_soggetto_options: {
          tipo_1: '1. Einzelnbegünstigter',
          tipo_2: '2. Eigentumswohnung',
        },
        subtitle:
          'Die auszufüllenden Pflichtfelder werden je nach Begünstigtersart angezeigt',
      },
      beneficiario: {
        title: 'Begünstigterdaten',
        rappresentante_beneficiario: {
          title: 'Daten zum Vertreter der Begünstigten',
          subtitle:
            'Nur auszufüllen, wenn der Unterzeichner der Mitteilung eine andere Person als der Begünstigte ist',
          cod_carica: 'Ladung-Code',
        },
      },
      condominium: {
        title: 'Eigentumswohnung',
        subtitle:
          'Nur auszufüllen, wenn Arbeiten an gemeinsamen Gebäudeteilen durchgeführt werden',
        min: 'Eigentumswohnungsminimum',
        cfAdmin: 'Steuernummer von Administrator/incaricato',
      },
      incaricato: {
        title:
          'Verpflichtung zur telematische Einreichung - Reserviert für Beauftragte',
        data: 'Zusagedatum',
      },
      visto_conformita: {
        title: 'Konformitätszertifikat - Reserviert für C.A.F. oder für Profi',
        cf_responsabile_caf: 'Steuernummer von verantwortlichen C.A.F.',
        cf_caf: 'Steuernummer von C.A.F.',
        cf_professionista: 'Steuernummer von Profi',
        firma_digitale:
          'Unterschrift des verantwortlichen C.A.F. oder des Profis',
      },
      asseverazione_efficienza_energetica: {
        title: 'Technischeszertifikat für Energieeffizienz',
        ci_enea: 'ENEA ID-Code',
        polizza_assicurativa: 'Versicherungspolice',
      },
      asseverazione_rischio_sismico: {
        title: 'Technischeszertifikat für Erdbebenrisiko',
        ci_asseverazione: 'ID-Code für Technischeszertifikat',
        cf_professionista: 'Steuernummer von Profi',
        polizza_assicurativa: 'Versicherungspolice',
      },
      quadro_a: {
        title: 'Teil A - Intervention',
        tipologia_intervento: 'Interventionart',
        intervento_superbonus: 'Superbonus',
        intervento_immobile_con_restrizione: 'Immobilie mit beschränkungen',
        unita_condominio: 'Eigentumseinheit',
        spesa: 'Aufwand €',
        anno_spesa: 'Aufwandjahr',
        periodo_anno: 'Period 2020',
        sal: 'Arbeitsfortschrittsstand',
        prot_telematico_prima_comunicazione:
          'Telematikprotokoll zur Übertragung der ersten Kommunikation',
        prog_telematico_prima_comunicazione:
          'Progressivprotokoll zur Übertragung der ersten Kommunikation',
        primo_anno_spesa: '1° Jahr des geleisteten Aufwandes',
      },
      quadro_b: {
        title: 'Teil B - Katasterdaten zur Identifizierung der Immobilie',
        subtitle:
          'Sie können die Anzahl der Zeilen ändern, indem Sie die Anzahl der Einheiten des Mehrparteiengebäudes über den Abschnitt A einstellen',
        cod_comune: 'Gebietcode',
        tipologia_immobile: 'Immobilieart',
        sez_urbana: 'Städtischer Abschnitt/Katastralgemeinde',
        foglio: 'Blatt',
        particella_numeratore: 'Zählerparzelle',
        particella_denominatore: 'Nennerparzelle',
        subalterno: 'Unterstellt',
        id_immobile: 'ID',
      },
      quadro_c: {
        title: 'Teil C - Option',
        sezione_1: {
          title: 'Abschnitt 1 - Begünstigter',
          tipo: 'Optionart',
          tipo_a: 'Beitrag in Form von Rabatt',
          tipo_b: 'Steuergutschriftabtretung',
          importo_credito_ceduto: 'Steuergutschrift abgetreten oder Beitrag €',
          rate_residue_credito_ceduto:
            'Steuergutschrift abgetreten (verbleibende Raten) €',
          rate_residue: 'N. verbleibende Raten',
          termine_presentazione: 'Bewerbungsfrist',
        },
        sezione_2: {
          title: 'Abschnitt 2 - Begünstigtersubjekten',
          subtitle:
            'Dieser Abschnitt ist nur auszufüllen, wenn Arbeiten an gemeinsamen Gebäudeteilen durchgeführt werden',
          tipo: 'Optionstyp',
          spesa: 'Kosten €',
          id_immobile: 'ID Immob.',
          credito_ceduto: 'Steuergutschrift abgetreten €',
          id_beneficiario: 'Begünstigter-ID',
        },
      },
      quadro_d: {
        title:
          'Teil D - Dati dei cessionari o dei fornitori che applicano lo sconto',
        subtitle:
          'Dieser Teil ist nur auszufüllen, wenn die angegebene Person seine Zustimmung gegeben hat',
        beneficiarioSingoloHint:
          "Im Fall 'Einzelbegünstigter' muss das Feld für jede Zeile gleich zu 1 sein",
        cf: 'Steuernummer von Übernehmer/Lieferant',
        data: 'Ausübungszeitpunkt',
        credito_ceduto: 'Steuergutschrift abgetreten €',
        tipologia_cessionario: 'Übernehmerart',
        id_beneficiario: 'Begünstigter-ID',
        tipologie: {
          tipo_1: '1.Lieferant',
          tipo_2: '2.Privatperson/Finanzvermittler',
          tipo_3: '3.Versicherungsunternehmen',
        },
      },
    },
    praticaAETransmission: {
      title: 'Übertragung der Unterlagen an Agenzia delle Entrate',
      step1: {
        title:
          'Laden Sie die Aufnahmedatei herunter, um sie auf den elektronischen Desktop von Agenzia delle Entrate hochzuladen',
        subtitle: 'File Record Agenzia Entrate (Formato: .txt)',
      },
      step2: {
        title:
          'Dateien von der Telematik-Desktop der Agenzia delle Entrate hochladen',
        noFile: 'Keine Datei geladen (Formati supportati: *.dcm)',
      },
      step3: {
        title: 'Übertragung bestätigen',
        subtitle:
          'Protokoll-Code eingeben, Übertragung von Agenzia delle Entrate bestätigen',
        prot_telematico_trasmissione: 'Protokoll Telematik Übertragung',
      },
    },
    praticaAEStatus: {
      title: 'Verwaltung des aktuellen Stand der Übermittlung der Unterlagen',
      step1: {
        title: 'Aktueller Stand der Übermittlung der Unterlagen',
      },
      step2: {
        title: 'Änderung des aktuellen Stand der Übermittlung der Unterlagen',
        newstatus: 'Neuer Stand',
        notes: 'Anmerkungen (facoltativo)',
      },
      statuses: {
        undefined: 'Unbekannt',
        Sent: 'Pec gesendet',
        Working: 'In Bearbeitung',
        ActionRequired: 'Handlung erforderlich',
        Canceled: 'Annulliert',
        Finished: 'Abgeschlossen',
      },
    },
    praticaAEList: {
      interventiList: 'Die zuvor ausgewählten MAßnahmen sind:',
      editedIn: 'Geändert am',
      completedListTitle: 'Abgeschlossene Unterlagen',
      draftListTitle: 'Nicht abgeschlossene Unterlagen',
      draftStatuses: {
        noDCM: 'DCM fehlt',
        draft: 'Entwurf',
        notSent: 'Protokoll fehlt',
      },
      emptyCompletedList: 'Nichts zu zeigen',
      emptyDraftList:
        'Nichts zu zeigen. Stellen Sie sicher, dass Sie MAßnahmen haben, die zu Agenzia delle Entrate mitgeteilt werden müssen',
      sal: 'SAL',
    },
  },
  cessioneCredito: {
    missingCredit: 'Kein Steuerguthaben für',
    maxCredit: 'Maximale Steuerabsetzung',
    maxCreditSal: 'Steuerabsetzung verfügbar',
    aloneInMarket: 'Wollen Sie alleine auf den Market gehen?',
    marketSubmit: 'An den Market senden',
    proceedTomarketSubmit: 'Weiter zum Market',
    sendToMarketSubmitDialog: {
      title: 'Senden an den Market bestätigen',
      subtitle:
        'Sind Sie sicher, dass Sie die Daten an den Market senden wollen?',
    },
    intervento: {
      importoSpesa: 'Ausgabebetrag',
      dataSpesa: 'Ausgabedatum',
      creditoImposta: 'Steuergutschrift',
      detraibileIn: 'Absetzbar in',
      nrRateResidue: 'Zahl der verbleibenden Raten',
      importoCreditoResiduo: 'Verbleibender Kreditbetrag',
      cessioneCredito: 'Steuergutschriftabtretung',
      scontoFattura: 'Rabatt in der Rechnung',
      dataEsercizio: 'Datum der Ausübung der Option',
      numeroProtocolloTelematico: 'Protocollo telematico Ident.',
      progressivo: 'Progressiv',
      rateResidue: 'Verbleibende Raten',
      creditoResiduo: 'verbleibender Kredit',
      startYear: 'Jahr Baubeginn',
      endYear: 'Jahr Bauende',
      tipo: 'Abtretung/Rabatt',
      stato: {
        SF: 'Rabatt in der Rechnung',
        CC: 'Steuergutschriftabtretung',
      },
    },
  },
  tabs: {
    summary: 'Zusammenfassung',
    ae: 'Agenzia Entrate',
    credit: 'Steuergutschriften',
  },
  errors: {
    title: 'Elemente nicht gefunden',
    summary: {
      no_property: 'Immobilie wurde nicht eingegeben',
      no_practices_id: 'Der Antrag wurde nicht korrekt registriert',
      no_delegate: 'Kunde fehlt',
      no_operations: 'Baumaßnahmen fehlen',
      no_enterprise: 'Bauunternehmen für eine oder mehrere Baumaßnahmen fehlt',
      no_cert_tecnical: 'Der technische Zertifizierer wurde nicht eingegeben',
      no_cert_fiscal: 'Der Steuerzertifizierer wurde nicht eingegeben',
      no_state_work:
        'Der Stand der Arbeiten für eine Baumaßnahme wurde nicht eingegeben',
    },
  },
};
