import general from "../../library/config/lang/de_general";
import errors from "../../library/config/lang/de_errors";
import profile from "../../library/config/lang/de_profile";
import practices from "../../library/config/lang/de_practices";
import privacyPolicy from "../../library/config/lang/de_privacy_policy";
import cookiePolicy from "../../library/config/lang/de_cookie_policy";

export default {
  practices,
  general,
  profile,
  app: {
    logout: "Logout",
    profile: "Profil",
    title: "App Name",
  },
  signIn: {
    firstName: "Vorname",
    lastName: "Nachname",
    email: "Email",
    fiscalCode: "Steuernummer",
    phoneNumber: "Telefonnummer",
    birthDate: "Geburtsdatum",
  },
  errors: {
    ...errors,
  },
  buttons: {
    confirm: "Bestätigung",
    save: "Speichern",
    cancel: "Annullieren",
    send: "Senden",
    delete: "Streichen",
  },
  dashboard: {
    header: {
      title: "Zugang zu ihrem Gebiet",
      subtitle: "Accounttyp auswählen",
    },
    tile1: {
      titleCard: "Operator",
      title: "Zugang zum Workspace",
      subtitle: "Unternehmen, technische Prüfer und Steuerzertifizierer",
    },
    tile2: {
      titleCard: "Endbegünstige",
      title: "Füllen Sie Ihre Anfrage aus",
      subtitle:
        "Kondominien und Verwalter von Kondominien, natürliche Personen, Institute für den sozialen Wohnbau, Genossenschaften, Organisationen für gemeinnützige Zwecke, Verbände",
    },
    tile3: {
      titleCard: "Plattform-Manager",
      title: "Nur Plattform-Manager-Zugriff",
    },
  },
  privacyPolicy: {
    ...privacyPolicy,
  },
  cookiePolicy: {
    ...cookiePolicy,
  },
};
