import React from 'react';
import { Toast } from 'react-bootstrap';
import { mergeClass } from '../functions/function';
import Icon from '../icon/Icon';
import ToastPropsStyle from './models/ToastProps';

interface ToastProps {
	className?: string;
	toastStyle: ToastPropsStyle;
	title: string;
	body?: string;
	showToast?: boolean;
	closeAction: () => void;
}
const ToastMsg = ({
	className,
	toastStyle,
	title,
	body,
	showToast = true,
	closeAction}: ToastProps) => {
	return (
		<div className={mergeClass('shared-toast', className)}>
			<Toast
				show={showToast}
				onClose={closeAction}
				style={{
					backgroundColor: toastStyle.style.bgColor,
					color: toastStyle.style.color,
				}}>
				<Toast.Header
					style={{
						backgroundColor: toastStyle.style.bgColor,
						color: toastStyle.style.color,
					}}>
					<Icon icon={toastStyle.icon.ico} color={toastStyle.icon.color} size="x26"></Icon>
					<strong className='mr-auto'>{title}</strong>
				</Toast.Header>
				{body && <Toast.Body>{body}</Toast.Body>}
			</Toast>
		</div>
	);
};

export default ToastMsg;
