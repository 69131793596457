export default {
  fe: {
    required: "Das Feld {{name}} ist obligatorisch",
    email: "Das Feld {{name}} enthält keine gültige E-mail",
    confirm: "Das Feld{{fieldCompare}} muss gleich zu dem Feld {{name}} sein",
    requiredOne: "Feld {{name}} oder Feld {{moreData.name}} ausfüllen",
    cf: "Das Feld {{name}} ist nicht korrekt",
    piva: "Das Feld {{name}} ist nicht korrekt",
    cfORpivaNotRequired:
      "Das Feld {{name}} muss ein gültigen Steuernummer oder Mehrwertsteuernummer",
    cap: "Das Feld {{name}} ist nicht korrekt",
    minLength: "Das Feld {{name}} muss mindestens {{min}} Zeichen haben",
    maxLength: "Das Feld {{name}} kann nicht mehr als {{max}} Zeichen haben",
    minDate: "Das Feld {{name}} kann nicht vorhergehend als {{value}} sein",
    maxDate: "Das Feld {{name}} kann nicht folgend als {{value}} sein",
    percentage: "Das Feld {{name}} beinhaltet kein Prozent",
    isNumber: "Das Feld {{name}} beinhaltet kein Nummer",
    upperCharacter: "Das Feld {{name}} muss mindestens ein Druckbuchstaben haben",
    oneNumber: "Das Feld {{name}} muss mindestens ein Nummer haben",
    specialCharacter:
      "Das Feld {{name}} muss mindestens ein Spezialzeichen haben @#!~$%^&*()-+/:.,<>?|",
      noPermission: 'Sie haben keine Berechtigung'
  },
};
