import React from "react";
import ErrorManagement from "../../library/redux/errors/ErrorManagement";
import Footer from "./footer/Footer";
import Header from "./header/Header";

interface AppLayoutProps {
  children: React.ReactNode;
}
const AppLayout = ({ children }: AppLayoutProps) => {
  return (
    <>
      <div className="containerApp">
        <ErrorManagement />
        <Header />
        <main>{children}</main>
        <Footer></Footer>
      </div>
    </>
  );
};

export default AppLayout;
