import React from "react";
import authRoute from "../../routes/auth";
import DynamicRoute from "./DynamicRoute";

const AuthRoute = () => {
  return (
    <div className="auth">
      <DynamicRoute routes={authRoute} />
    </div>
  );
};

export default AuthRoute;
