import React from 'react';
import { mergeClass } from '../../functions/function';

interface LoaderProps {
	className?: string;
	size?: string;
}

const Loader = ({ className, size }: LoaderProps) => {
	return (
			<div className={mergeClass('lds-spinner', size)}>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
	);
};
export default Loader;
