import React from 'react';
import BoxFullScreen from '../../boxFullScreen/BoxFullScreen';

import Loader from '../loader/Loader';


interface LoaderProps {
	children?: React.ReactNode;
	className?: string;
	show?: boolean;
}

const LoaderFullScreen = ({ children, className, show = false }: LoaderProps) => {
	return (
		<BoxFullScreen show={show}>
			<div className="loader">
			<Loader></Loader>
			</div>
		</BoxFullScreen>
	);
};
export default LoaderFullScreen;
