import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import it from "./lang/it";
//import en from "./lang/en";
import de from "./lang/de";

export const dropdownSelectableLangs = [
  { label: "Italiano", langCode: "it" },
  { label: "Deutsch", langCode: "de" },
  //{ label: "English", langCode: "en" },
];

const resources = {
  // en: {
  //   translation: en,
  // },
  it: {
    translation: it,
  },
  de: {
    translation: de,
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    //lng: "it",  //  commented to make languagedetector working. enable if u want to override missing strings with the fallback lang
    //fallbackLng: "it",
    supportedLngs: ["it", /* "en", */ "de"],
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
