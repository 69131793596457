import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

const initialState: { n: number } = {
  n: 0,
};

export const loadSlice = createSlice({
  name: "load",
  initialState,
  reducers: {
    incrementN: (state) => {
      state.n = state.n + 1;
    },
    decrementN: (state) => {
      state.n = state.n - 1;
    },
  },
});

export const { incrementN, decrementN } = loadSlice.actions;

export const selectActiveLoad = (state: RootState) => !!state.load.n;

export default loadSlice.reducer;
