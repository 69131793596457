import React from 'react';
interface IconProps extends React.HTMLAttributes<HTMLOrSVGElement> {
	icon: string;
	size?: string;
	color?: string;
	colorStyle?: string;
	disabled?: boolean;
	pointer?: boolean;
	label?: string | React.ReactNode;
}

const Icon = ({
	icon,
	size = '',
	color = '',
	colorStyle,
	children,
	className = '',
	disabled = false,
	label = '',
	pointer = true,
	...rest
}: IconProps) => {
	const classDisabled = disabled ? 'disabled' : '';
	const styleColor = { '--color-icon-var': colorStyle } as React.CSSProperties;
	return (
		<div
			className={'shared-icon ' + className + ' ' + (pointer ? 'pointer' : '')}
			{...rest}>
			<span
				style={styleColor}
				className={
					'icon ' +
					'icon-' +
					icon +
					' ' +
					size +
					' ' +
					color +
					' ' +
					classDisabled
				}></span>
			{label ? (
				<span className={classDisabled + ' ' + 'iconLabel'} style={styleColor}>{label}</span>
			) : null}
		</div>
	);
};

export default Icon;
