import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getNazioni,
  getProvince,
  getComuni,
} from "../../services/domain-api/birthplace.service";
import { ComuneModel } from "./models/ComuneModel";
import { ProvinciaModel } from "./models/ProvinciaModel";
import { NazioneModel } from "./models/NazioneModel";
import { arrayObjSort } from "../../utils/sortArray";
import {
  AppThunkBirth,
  BirthStateModel,
  InitialStateProps,
} from "./models/BirthPlaceStateModel";

const initialState: InitialStateProps = {
  comuni: [],
  province: [],
  nazioni: [],
};
export const birthplaceSlice = createSlice({
  name: "birthplace",
  initialState,
  reducers: {
    setComuni: (state, action: PayloadAction<ComuneModel[]>) => {
      state.comuni = action.payload;
    },
    setProvince: (state, action: PayloadAction<ProvinciaModel[]>) => {
      state.province = action.payload;
    },
    setNazioni: (state, action: PayloadAction<NazioneModel[]>) => {
      state.nazioni = [
        { codice_belfiore: "IT", descrizione: "ITALIA" },
        ...action.payload,
      ];
    },
  },
});
export const { setComuni, setNazioni, setProvince } = birthplaceSlice.actions;

export const loadProvince = (): AppThunkBirth => (dispatch) => {
  if (localStorage.getItem("provincie")) {
    dispatch(setProvince(JSON.parse(localStorage.getItem("provincie") as any)));
  } else {
    getProvince().then(
      (res: any) => {
        const sortProvince = arrayObjSort(res, "descrizione");
        localStorage.setItem("provincie", JSON.stringify(sortProvince));
        dispatch(setProvince(sortProvince));
      },
      (err) => {
        console.log("error province", err);
      }
    );
  }
};

export const loadNazioni = (): AppThunkBirth => (dispatch) => {
  if (localStorage.getItem("nazioni")) {
    dispatch(setNazioni(JSON.parse(localStorage.getItem("nazioni") as any)));
  } else {
    getNazioni().then(
      (res: any) => {
        const filterNazioni = res.reduce((arr: any[], naz: any) => {
          if (!arr.find((el) => el.descrizione === naz.descrizione)) {
            arr.push(naz);
          }
          return arr;
        }, []);
        localStorage.setItem("nazioni", JSON.stringify(filterNazioni));
        dispatch(setNazioni(filterNazioni));
      },
      (err) => {
        console.log("error nazioni", err);
      }
    );
  }
};

export const loadComuni = (data: string = "RM"): AppThunkBirth => (
  dispatch
) => {
  getComuni(data).then(
    (res: any) => {
      dispatch(setComuni(res));
    },
    (err) => {
      console.log("error comuni", err);
    }
  );
};

export const loadInitBirthPlaceSlice = (): AppThunkBirth => (
  dispatch,
  getState
) => {
  if (!getState().birthplace.nazioni || !getState().birthplace.nazioni.length) {
    dispatch(loadNazioni());
    dispatch(loadProvince());
  }
};

export const selectComuni = (state: BirthStateModel) => state.birthplace.comuni;
export const selectProvince = (state: BirthStateModel) =>
  state.birthplace.province;
export const selectNazioni = (state: BirthStateModel) =>
  state.birthplace.nazioni;

export default birthplaceSlice.reducer;
