import React from "react";
import { FormGroup } from "react-bootstrap";
import Select from "../form/select/Select";

interface IProps {
  availableLanguages: { label: string; langCode: string }[]; // Es: [{label:"italiano", langCode: "it"}]
  selectedLanguage: string;
  onSelectedLanguageChange: (nextLanguage: string) => void;
}

const LanguageSelector = (props: IProps) => {
  return (
    <FormGroup>
    <Select
      className="md"
      name="select"
      options={props.availableLanguages.map((value) => {
        return { label: value.label, value: value.langCode };
      })}
      changeSelect={props.onSelectedLanguageChange}
      value={props.selectedLanguage}
    />
    </FormGroup>
  );
};

export default LanguageSelector;
