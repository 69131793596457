import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.scss";

import AppLayout from "./components/app-layout/AppLayout";
import AuthRoute from "./components/routes/AuthRoute";
import useWellKnown from "./library/auth/wellKnown";

function App() {
  const wellKnown = useWellKnown();

  return (
    <>
      {wellKnown && wellKnown.metadata && (
        <Router>
          <AppLayout>
            <Switch>
              <Route path="/">
                <AuthRoute />
              </Route>
            </Switch>
          </AppLayout>
        </Router>
      )}
    </>
  );
}

export default App;
