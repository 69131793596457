import { createSlice } from "@reduxjs/toolkit";

interface initialStateProps {
 n: number
}
const initialState: initialStateProps= {
  n: 0,
};

export const loadSlice = createSlice({
  name: "load",
  initialState,
  reducers: {
    incrementN: (state) => {
      state.n = state.n + 1;
    },
    decrementN: (state) => {
      state.n = state.n > 0? state.n - 1 : 0;
    },
  },
});

export const { incrementN, decrementN } = loadSlice.actions;

export const selectActiveLoad = (state: {load: initialStateProps}) => !!state.load.n;

export default loadSlice.reducer;
