export default {
  title: 'Cookie Policy',
  p1: `La presente Cookie Policy ha lo scopo di descrivere le modalità
  di gestione di questo Sito, in riferimento all'uso dei cookie ed
  al trattamento dei dati personali degli utenti/visitatori che lo
  consultano.`,
  p2: `Questa Cookie Policy ha lo scopo di informare gli utenti in
  merito alle procedure seguite per la raccolta, tramite i cookie
  e/o altre tecnologie di monitoraggio, delle informazioni fornite
  visitando il sito web (di seguito definito il "Sito") e deve
  essere intesa come parte integrante della Privacy Policy del
  medesimo`,
  p3: `Il Titolare del Trattamento dei dati personali raccolti
  attraverso il Sito è la società TechVisory srl con sede in Via
  Kufstein 5, 38121 Trento (di seguito "la Società" o "il
  Titolare"), che tratterà gli stessi in conformità a quanto
  stabilito dalla normativa applicabile in materia di privacy e
  protezione dei dati personali.`,
  ol1: {
    title: `Dati di navigazione e loro conservazione`,
    p1: `I sistemi informatici e le procedure software preposte al
    funzionamento di questo Sito acquisiscono, nel normale
    esercizio, alcuni dati che vengono trasmessi implicitamente
    nell'uso dei protocolli di comunicazione Internet.`,
    p2: `Si tratta di informazioni che non sono raccolte per essere
    associate ad interessati identificati, ma che per loro natura
    potrebbero, anche mediante elaborazioni con dati detenuti da
    terzi, permettere di identificare gli utenti/visitatori (ad es.
    indirizzo IP, nomi di domini dei computer utilizzati dagli
    utenti che si collegano al Sito, il codice indicante lo stato di
    risposta dati dal server, tipo di browser, indicazioni temporali
    di inizio e fine della sessione di navigazione, eventuale
    identificativo dell'account e delle attività svolte nel Sito,
    ecc.).`,
    p3: `Questi dati vengono utilizzati solo per informazioni di tipo
    statistico e per controllare il corretto funzionamento del Sito.
    I dati stessi vengono conservati al massimo per sei mesi, fatta
    salva l'ulteriore conservazione per eventuali accertamenti di
    reati informatici ai danni del Sito o per rispondere a specifici
    obblighi normativi.`,
    p4: `Nessun dato derivante dal servizio web verrà diffuso.`
  },
  ol2: {
    title: `Definizione di cookie`,
    p1: `I cookie sono piccoli file di testo che i siti internet visitati
    dagli utenti inviano ai loro terminali (solitamente al browser),
    dove vengono memorizzati per essere poi ritrasmessi agli stessi
    siti ad ogni successiva visita tramite il medesimo terminale.
    Inoltre, ogni sito può consentire la trasmissione dei c.d.
    cookie di "terze parti", cioè quelli generati da siti internet
    diversi da quello che l'utente sta visitando (attraverso oggetti
    in esso presenti quali banner, immagini, mappe, suoni, specifici
    link a pagine web di altri domini).`,
    p2: `	In funzione della loro durata, si distinguono in cookie di
    sessione (cioè quelli temporanei e cancellati automaticamente
    dal terminale al termine della sessione di navigazione,
    chiudendo il browser) ed in cookie persistenti (cioè quelli che
    restano memorizzati sul terminale fino alla loro scadenza o
    cancellazione da parte dell'utente).`,
    p3: `I cookie hanno diverse finalità. Sono in primo luogo utilizzati
    per la trasmissione della comunicazione o per fornire il
    servizio richiesto dall'utente; più precisamente permettono di
    abilitare ed ottimizzare il funzionamento del sito internet.`,
    p4: `	I suddetti cookie sono detti "tecnici" (per il loro utilizzo non
      è necessario il consenso dell'utente), in quanto senza di essi
      alcune delle citate operazioni non potrebbero essere compiute o
      sarebbero più complesse e/o meno sicure.`,
    p5: `Diversamente, se i cookie sono utilizzati per ulteriori
    finalità, tipicamente per analisi del comportamento ed invio di
    messaggi promo-pubblicitari personalizzati (c.d. cookie di
    "profilazione") o anche solo per ottenere, per il tramite di
    servizi resi da terze parti, informazioni in forma aggregata sul
    numero degli utenti e su come questi visitano il sito (c.d.
    "analytics cookie"), è necessario il consenso dell'utente.
    Infatti, prima dell'invio di questi cookie sul terminale, ai
    sensi della normativa vigente (Codice privacy e provvedimento
    generale del Garante dell'8 maggio 2014), al momento in cui si
    accede alla home page o altra pagina del sito, è immediatamente
    mostrato in primo piano un banner con una prima informativa
    sintetica sull'uso dei cookie e sulla raccolta del consenso, che
    l'utente può prestare ad esempio proseguendo la navigazione
    tramite la selezione di un elemento sottostante al banner o
    chiudendo il banner stesso.`
  },
  ol3: {
    title: `Utilizzo dei cookie in questo Sito`,
    p1: `La Società utilizza i cookie al fine di fornire ai propri utenti
    un'esperienza di navigazione modellata il più possibile intorno
    ai suoi gusti e preferenze. Tramite i cookie la Società fa in
    modo che ad ogni visita sul sito non si debbano ricevere o
    inserire le stesse informazioni. I cookie vengono utilizzati
    anche al fine di ottimizzare le prestazioni del sito web: al
    momento del collegamento al Sito tramite il browser, potrebbero
    essere installati sul dispositivo dell'utente cookie al fine di
    riconoscere il dispositivo utilizzato, garantire la fruizione
    del Sito per la durata del periodo di validità dei cookie e
    raccogliere informazioni sugli accessi al Sito stesso.`,
    p2: `Al fine di proteggere i propri dati personali da possibili
    perdite e da qualsiasi forma di trattamento illecito la Società
    ha adottato adeguate misure di sicurezza tecniche ed
    organizzative.`,
    p3: `	Durante la navigazione sul Sito, l'utente può ricevere sul suo
    terminale anche cookie che sono inviati da siti o da web server
    diversi (c.d. "terze parti"), sui quali possono risiedere alcuni
    elementi (es.: immagini, mappe, suoni, specifici link a pagine
    di altri domini) presenti sul sito che lo stesso sta visitando.
    Possono esserci, quindi, sotto questo profilo:`,
    ol1: {
      li1: `Cookie di prima parte (first-part cookies), sono inviati
      direttamente dalla Società al dispositivo dell'utente. Questi
      cookie, tecnici e di performance, sono limitati: nel primo
      caso alla trasmissione di identificativi di sessione
      (costituiti da numeri casuali generati dal server) che
      consentono una navigazione sicura ed efficiente del sito web;
      nel secondo caso utilizzati a fini statistici per la
      rilevazione degli utenti unici, dei contenuti visualizzati o
      scaricati ecc., ne è un esempio Google Analytics.`,
      li2: `Cookie di terza parte, (third-part cookies), come ad esempio
      quello impostato dal pulsante "Mi piace" di Facebook, sono
      cookie generati e utilizzati da soggetti diversi dal gestore
      del sito sul quale l'utente sta navigando, sulla base di
      contratti tra titolare del sito web e la relativa terza parte.
      Alcuni inserzionisti pubblicitari utilizzano questi cookie per
      tracciare le visite dell'utente sui siti nei quali offrono i
      propri servizi.`
    },
    p4: `	è possibile modificare il browser per disattivare i cookie
    tramite una procedura molto semplice. Attenzione: disattivando i
    cookie sia nome utente che password non saranno più memorizzati
    sul box di login del sito`,
    p5: `In base alla normativa vigente in Italia, per l'utilizzo dei
    cookie non sempre è richiesto un espresso consenso dell'utente.
    In particolare, non richiedono tale consenso i "cookie tecnici",
    cioè quelli indispensabili per il funzionamento del sito o
    necessari per eseguire attività richieste dall'utente.`,
    p6: `In funzione della finalità di utilizzazione dei cookie, questi
    si possono distinguere in:`,
    ol2: {
      li1: `Cookie Tecnici: il loro uso è strettamente limitato alla
      trasmissione di dati identificativi di sessione (numeri
      casuali generati dal server), necessari per consentire
      l'esplorazione sicura ed efficiente del Sito; essi non vengono
      memorizzati in modo persistente sul computer dell'utente e
      svaniscono con la chiusura del browser. L'uso di tali cookie è
      finalizzato alla sicurezza e miglioramento del servizio
      offerto. In particolare la società utilizza i seguenti Cookie
      Tecnici:`,
      li2: `Cookie Analytics: WFP utilizza cookie analytics al fine di
      analizzare il numero degli utenti e le modalità di utilizzo
      del Sito, informazioni che vengono raccolte in forma
      aggregata. In particolare la società utilizza i seguenti
      Cookie Analytics;`,
      li3: `Cookie di Profilazione: i cookie di profilazione sono volti a
      creare profili relativi all'utente e vengono utilizzati al
      fine di inviare messaggi pubblicitari in linea con le
      preferenze manifestate dallo stesso nell'ambito della
      navigazione in rete o per migliorarne l'esperienza di
      navigazione. Questi cookie sono utilizzabili per tali scopi
      soltanto con il consenso dell'utente.`
    },
    p7: `Di seguito la descrizione dei cookie di profilazione di prima e
    di terza parte utilizzati sul Sito.`
  },
  ol4: {
    title: `Gestione dei cookie tramite le impostazioni del browser`,
    p1: `L'utente può selezionare quali cookie autorizzare attraverso
    l'apposita procedura predisposta nella sezione Impostazioni
    relative ai cookie, nonché autorizzare, bloccare o cancellare
    (in tutto o in parte) i cookie attraverso le specifiche funzioni
    del proprio programma di navigazione (c.d. browser) oppure
    attraverso l'uso di specifici software: tuttavia, nell'ipotesi
    in cui tutti o alcuni dei cookie vengano disabilitati è
    possibile che il Sito risulti non consultabile o che alcuni
    servizi o determinate funzioni del Sito non siano disponibili o
    non funzionino correttamente e/o l'utente potrebbe essere
    costretto a modificare o a inserire manualmente alcune
    informazioni o preferenze ogni volta che visiterà il Sito. Per
    avere maggiori informazioni su come impostare le preferenze
    sull'uso dei cookie attraverso il proprio browser di
    navigazione, è possibile consultare le relative istruzioni:`,
    ul1: {
      li1: `Internet Explorer: https://support.microsoft.com/it-it/help/17442/windows-internet-explorer-delete-manage-cookies`,
      li2: `Firefox: https://support.mozilla.org/it/kb/Attivare%2520e%2520disattivare%2520i%2520cookie`,
      li3: `Chrome: https://support.google.com/chrome/answer/95647?hl=it&topic=14666&ctx=topicc`,
      li4: `Safari: https://support.apple.com/kb/index?page=search&fac=all&q=cookies%2520safari`
    },
    p2: `Si informa al riguardo che Google (  
        https://support.google.com/analytics/answer/60042455
      ) fornisce ai visitatori, che non vogliono che le informazioni
      relative alla loro navigazione siano inviate a Google Analytics,
      la possibilità di installare un "opt-out browser add-on"
      disponibile per i browser più moderni (vedi
        https://tools.google.com/dlpage/gaoptout
      ), attraverso cui i cookies di Google Analytics possono essere
      rifiutati. Pertanto, l'utilizzo del Sito senza rifiutare i
      cookies di Google implica il consenso dell'utente al trattamento
      dei propri dati da parte di Google, limitatamente alle modalità
      e finalità sopraindicate. Per consultare l'informativa privacy
      della società Google, relativa al servizio Google Analytics,
      l'utente è pregato di visitare il sito Internet
      http://www.google.com/analytics/learn/privacy.html`,
    p3: `Per conoscere le norme sulla privacy di Google, l'Utente è
      pregato di visitare il sito Internet
        http://www.google.com/intl/it/privacy/privacy-policy.html`,
    p4: `Se si usa un dispositivo mobile, occorre fare riferimento al suo
      manuale di istruzioni per scoprire come gestire i cookie.`,
    p5: `Se l'utente non desidera associare la visita delle pagine del
      Sito al proprio account social, deve effettuare il log-off. Di
      seguito si riportano le modalità offerte dai principali social
      network per consentire all'utente di disabilitare i social
      plugin:`,
    ul2: {
      li1: `Facebook: accedere al proprio account, sezione privacy`,
      li2: `	Twitter:
          https://twitter.com/settings/security`,
      li3: `	LinkedIn:
          https://www.linkedin.com/settings/`,
      li4: `Google+:
          http://www.google.it/intl/it/policies/technologies/managing/`
    },
    p6: `Per maggiori informazioni sull'elaborazione e l'utilizzo dei
      dati attraverso i social network, così come sulle impostazioni
      per la tutela della privacy, è opportuno verificare le privacy
      policy dei rispettivi social network o siti web. Di seguito sono
      riportati i link in questione:`,
    ul3: {
      li1: `Facebook: https://www.facebook.com/help/cookies/`,
      li2: `	Twitter:
        https://support.twitter.com/articles/20170514`,
      li3: `	LinkedIn:
        https://www.linkedin.com/legal/cookie-policy`,
      li4: `Google+:
        http://www.google.it/intl/it/policies/technologies/cookies/`
    },
    p7: `Per maggiori informazioni sul trattamento dei dati personali
      dell'utente, consulta l'informativa privacy presente sul sito.`
  },


  ol5: {
    title: `Modalità del trattamento`,
    p1: `Il trattamento viene effettuato attraverso strumenti informatici
    e telematici e/o manualmente (ad es. su supporto cartaceo) per
    il tempo strettamente necessario a conseguire gli scopi per i
    quali i dati sono stati raccolti e comunque, in conformità alle
    disposizioni normative vigenti in materia.`,
  },
  ol6: {
    title: `Facoltatività del conferimento dei dati`,
    p1: `	A parte quanto specificato per i dati di navigazione, gli
    utenti/visitatori sono liberi di fornire i propri dati
    personali. Il loro mancato conferimento può comportare
    unicamente l'impossibilità di ottenere quanto richiesto.`,
    p2: `	Per maggiori informazioni sul trattamento dei dati personali e
    sui diritti dell'utente, consulta l'informativa privacy del
    sito.`
  }
}
