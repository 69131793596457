import { ProvinciaModel } from "../../redux/birthplace/models/ProvinciaModel";
import { NazioneModel } from "../../redux/birthplace/models/NazioneModel";
import { ComuneModel } from "../../redux/birthplace/models/ComuneModel";
import http from "../../utils/http";

export const getProvince = () => {
  return http.get<ProvinciaModel[]>(`domini/province/`);
};

export const getNazioni = () => {
  return http.get<NazioneModel[]>(`domini/nazioni/`);
};

export const getComuni = (provincia: string) => {
  return http.get<ComuneModel[]>(
    `domini/province/${provincia}/comuni?attivo=true`
  );
};
