import general from "../../library/config/lang/it_general";
import errors from "../../library/config/lang/it_errors";
import profile from "../../library/config/lang/it_profile";
import practices from "../../library/config/lang/it_practices";
import privacyPolicy from "../../library/config/lang/it_privacy_policy";
import cookiePolicy from "../../library/config/lang/it_cookie_policy";

export default {
  practices,
  general,
  profile,
  app: {
    logout: "Logout",
    profile: "Profilo",
    title: "Nome app",
  },
  signIn: {
    firstName: "Nome",
    lastName: "Cognome",
    email: "Email",
    fiscalCode: "Codice fiscale",
    phoneNumber: "Telefono",
    birthDate: "Data di nascita",
  },
  errors: {
    ...errors,
  },
  buttons: {
    confirm: "Conferma",
    save: "Salva",
    cancel: "Annulla",
    send: "Invia",
    delete: "cancella",
  },
  dashboard: {
    header: {
      title: "Accedi alla tua area dedicata",
      subtitle: "Seleziona il tipo di Account",
    },
    tile1: {
      titleCard: "Operatore",
      title: "Accedi alla tua workspace",
      subtitle: "Imprese, Asseveratori tecnici e Certificatori Fiscali",
    },
    tile2: {
      titleCard: "Beneficiario finale",
      title: "Compila la tua pratica",
      subtitle:
        "Condomini e Amministratori di Condominio, Persone fisiche, IACP, Cooperative, Organizzazioni di volontariato, Associazioni",
    },
    tile3: {
      titleCard: "Gestione della Piattaforma",
      title: "Accesso riservato al gestore della piattaforma",
    },
  },
  privacyPolicy: {
    ...privacyPolicy,
  },
  cookiePolicy: {
    ...cookiePolicy,
  },
};
