import React from "react";
import ToastListError from "../../elements/errors/toast/toastListError/ToastListError";
import {selectErrors, removeError} from "./errorSlice";
import {useDispatch, useSelector} from "react-redux";

const ErrorManagement = () => {
  const list = useSelector(selectErrors);
  const dispatch = useDispatch();
  return (
    <ToastListError list={list} closeAction={(id) => dispatch(removeError(id))}/>
  );
};

export default ErrorManagement;
